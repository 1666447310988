import React from "react";
import styles from "./modalWaitConfirmTrans.module.scss";
import watch from "../../../../assets/image/imgForOpenMysteryBoxPage/watch.png"

export const ModalWaitConfirmTrans: React.FC = () => {

  return (
    <div className={styles.modal}>
      <div>please confirm the transaction in metamask</div>
      <img src={watch} alt={''}/>
    </div>
  )
};

