import React, {useContext, useEffect} from 'react'
import './App.scss';

import {Route, Routes} from 'react-router-dom';
import {MainPage} from './MainPage';
import {MysteryBoxPage} from './MysteryBoxPage';
import OpenMysteryBoxPage from "./openMysteryBoxPage/openMysteryBoxPage/openMysteryBoxPage";
import {Context} from "./index";

function App() {
  const {storeUser} = useContext(Context);
  useEffect(() => {
    const checkAuth = async () => {
      if (localStorage.getItem("accessToken")) {
        await storeUser.checkAuth();
      }
    }
    checkAuth();
  }, []);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainPage/>
        }
      />
      <Route
        path="/nft"
        element={
          <MysteryBoxPage/>
        }
      />
      <Route
        path="/open_box"
        element={
          <OpenMysteryBoxPage/>
        }
      />
    </Routes>
  );
}

export default App;
