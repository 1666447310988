import React from "react";

export const SvgLogoTelegram: React.FC = () => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.10353 6.53255L11.8255 2.08029C12.8839 1.61651 16.4733 0.132425 16.4733 0.132425C16.4733 0.132425 18.1299 -0.516863 17.9918 1.05998C17.9458 1.70927 17.5777 3.98177 17.2095 6.43979L16.0591 13.7211C16.0591 13.7211 15.9671 14.7878 15.1848 14.9733C14.4025 15.1588 13.114 14.324 12.8839 14.1385C12.6999 13.9994 9.43264 11.9124 8.23619 10.892C7.91407 10.6138 7.54593 10.0572 8.28221 9.40796C9.93883 7.8775 11.9176 5.97601 13.114 4.77019C13.6662 4.21366 14.2184 2.91509 11.9176 4.49193L5.42915 8.89781C5.42915 8.89781 4.69287 9.36158 3.31236 8.94418C1.93184 8.52678 0.321243 7.97025 0.321243 7.97025C0.321243 7.97025 -0.783169 7.27459 1.10353 6.53255Z"
        fill="white"/>
    </svg>
  );
};
