import React from "react";

import styles from "./OwningLandIn.module.scss";
import grass from '../../assets/imageBox/Grass.png'
import cl from "classnames";

export const OwningLandIn: React.FC = () => {

  return (
    <div className={styles.content}>
      <div className={styles.subTitle}>
        <span>Owning a land in the Pixie Metaverse is essential</span>
      </div>
      <div className={styles.left}>
        <div className={styles.letfTop}>
          <div className={styles.letfItem}>
            <div className={styles.letfItemTitle}>
              1%
            </div>
            <div className={styles.letfItemDescription}>
              from every game pool is distributed
            </div>
          </div>
          <div className={styles.letfItem}>
            <div className={styles.letfItemTitle}>
              7500
            </div>
            <div className={cl(styles.letfItemDescription, styles.forLandloards)}>
              landlords receive <br/> the profit
            </div>
          </div>
        </div>
        <div className={styles.letfBottom}>
          <div className={styles.letfItem}>
            <div className={styles.letfItemTitle}>
              5
            </div>
            <div className={styles.letfItemDescription}>
              land exclusivity levels are available
            </div>
          </div>
          <div className={styles.letfItemText}>
            Combine your Land with Houses and Licences and earn up to 120 times more
          </div>
        </div>
      </div>
      <img className={styles.img} src={grass} alt=""/>
    </div>
  );
};

