import React from "react";
import styles from "./modalWait.module.scss";
import watch from "../../../../assets/image/imgForOpenMysteryBoxPage/watch.png"

export const ModalWait: React.FC = () => {

  return (
    <div className={styles.modal}>
      <div>please wait while your nft is being minted</div>
      <img src={watch} alt={''}/>
    </div>
  )
};

