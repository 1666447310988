import React, {useEffect, useState} from "react";
import styles from "./OpenNftModal.module.scss";
import mysteryBox from "../../../../assets/image/imgForOpenMysteryBoxPage/mysteryBoxGif.gif"
import cl from "classnames";
import {getEthereumAccountInfo, IEthereumAccountInfo} from "../../../../utills/metamask/metamask";
import {ethers} from "ethers";
import {ADDRESS_CONTRACT_NFT, NET} from "../../../../config/config";
import abiContractNft from "../../../../openMysteryBoxPage/sectionMysteryBoxes/abiNftContract.json";
import {toast} from "react-toastify";
import {CHAIN_SETTINGS} from "../../../../utills/metamask/networkParams";


export const OpenNftModal: React.FC<{ idPack: number }> = ({idPack}) => {
  const [isAnimation, setIsAnimation] = useState<boolean>(true);
  const [isAnimation2, setIsAnimation2] = useState<boolean>(true);
  const [arrayNft, setArrayNft] = useState<any[]>([]);
  const [imageNft, setImgNft] = useState('');
  const getNft = async () => {
    try {
      const ethereumAccount: IEthereumAccountInfo = await getEthereumAccountInfo(NET === 'mainnet' ? CHAIN_SETTINGS.BNB_MAINNET.id : 97) as IEthereumAccountInfo;
      const nftContract = await new ethers.Contract(ADDRESS_CONTRACT_NFT, abiContractNft, ethereumAccount.signer);
      const tx: any[] = await nftContract.getMyNFTs();
      const items = await Promise.all(
        tx.map(async (box: any) => {
          const item = await fetch(box.nft_type_1).then((response) => response.json());
          return {
            image: item.image,
            tokenId: box.tokenId,
            minted: box.minted,
          };
        })
      );
      items.map((el) => {
        if (el.tokenId == idPack) {
          setImgNft(el.image)
        }
      });
      setArrayNft(items);
    } catch (error) {
      console.log('error get nft', error);
      toast.error("error get nft");
    }
  };


  useEffect(() => {
    getNft().then(() => {
      setTimeout(() => {
        setIsAnimation(false);
      }, 1500)
      setTimeout(() => {
        setIsAnimation2(false);
      }, 3000)
      console.log(idPack)
      console.log('metadata', arrayNft[idPack])
    });


  }, [])
  return (
    <div className={cl(styles.modal)}>
      {isAnimation &&
        <img src={mysteryBox} alt={''}/>
      }
      <img src={imageNft} alt={''} className={styles.imgNft}/>
    </div>
  )
};

