const MAINNETWORK = "mainnet";
const TESTNETWORK = "testnet";

// Here you can change the network !
export const NET: string = TESTNETWORK

const LOCAL_SERVER_DOMAIN = "http://localhost:4003";
const DEV_SERVER_DOMAIN = "https://landing-backend.pixie.fun";

export const SERVER_DOMAIN = DEV_SERVER_DOMAIN;

const BNB_RECIPIENT_ADDRESS_TESTNET = '0x9A0aA4C5B3b029e17d91f861745C0889001dd9B0';
const BNB_RECIPIENT_ADDRESS_MAINNET = '';

const BUSD_CONTRACT_ADDRESS_TESTNET = '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee';
const BUSD_CONTRACT_ADDRESS_MAINNET = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';

const BUSD_RECIPIENT_ADDRESS_TESTNET = '0x9A0aA4C5B3b029e17d91f861745C0889001dd9B0';
const BUSD_RECIPIENT_ADDRESS_MAINNET = '';

export const BUSD_CONTRACT_ADDRESS = NET === 'mainnet' ? BUSD_CONTRACT_ADDRESS_MAINNET : BUSD_CONTRACT_ADDRESS_TESTNET;
export const BUSD_RECIPIENT_ADDRESS = NET === 'mainnet' ? BUSD_RECIPIENT_ADDRESS_MAINNET : BUSD_RECIPIENT_ADDRESS_TESTNET;
export const BNB_RECIPIENT_ADDRESS = NET === 'mainnet' ? BNB_RECIPIENT_ADDRESS_MAINNET : BNB_RECIPIENT_ADDRESS_TESTNET;

const TESTNET_BSC_RPC_NODES = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
const MAINNET_BSC_RPC_NODES ='https://endpoints.omniatech.io/v1/bsc/mainnet/public';

export const RPC_BSC:string = NET ==='mainnet'? MAINNET_BSC_RPC_NODES:TESTNET_BSC_RPC_NODES;
export const AMOUNT_NFT_BOXES:number=15864;

const ADDRESS_CONTARCT_NFT_TESTNET = '0x036538e20319079ab3329d9fb9e5794FEBCdac4f';
const ADDRESS_CONTARCT_NFT_MAINNET = '0x7057E428f5f430F8F78397EeaBEDFBa6d1f0b39c';

export const ADDRESS_CONTRACT_NFT= NET === 'mainnet'?ADDRESS_CONTARCT_NFT_MAINNET:ADDRESS_CONTARCT_NFT_TESTNET;