import React, { useEffect, useState } from 'react';
import styles from './BoxInformationArea.module.scss';

import gradientLine from '../../assets/imageBox/gradientLine.png';
import { modal } from "../modal";
import { ModalMysteryBox } from "../modal/components/ModalMysteryBox/ModalMysteryBox";
import { ADDRESS_CONTRACT_NFT, AMOUNT_NFT_BOXES, RPC_BSC } from "../../config/config";
import abiContractNft from "../../openMysteryBoxPage/sectionMysteryBoxes/abiNftContract.json";
import borderForBarInBoxInformationArea from "../../assets/imageBox/borderForBarInBoxInformationArea.png";
import borderForProgressBarMobileVersion from "../../assets/imageBox/borderForProgressBarMobileVersion.png";

import cl from "classnames";
import Web3 from "web3";
import { AbiItem } from "web3-utils";

const BoxInformationArea: React.FC<any> = ({ className }) => {
  const [countNft, setCounHft] = useState<number>(1);
  const [collectedMoney, setCollectedMoney] = useState<number>(1);

  const getCountNft = async () => {
    try {
      const web3 = new Web3(RPC_BSC);
      const contract = new web3.eth.Contract(
        abiContractNft as AbiItem[],
        ADDRESS_CONTRACT_NFT
      );
      const result = await contract.methods.getCurrentToken().call();

      setCounHft(Number(result) + 9058);
      setCollectedMoney(Number(result) * 70 + 101000);
    } catch (error) {
      console.error('error get nft', error);
    }
  };


  useEffect(() => {
    getCountNft();
    setInterval(() => {
      getCountNft();
    }, 20000);
  }, [])


  return (
    <div className={cl(styles.area, className)}>
      <div className={styles.header}>
        <div className={styles.informAboutRound}>
          <span className={styles.greenText}>PIX1 Round</span><br />
          starts in
        </div>
        <div className={styles.discount}>93.5% discount</div>
      </div>
      <div className={styles.timer}>
        <div className={styles.cellTimer}>
          <div className={styles.nameCell}>days</div>
          <div>0</div>
        </div>
        <div className={styles.colon}>
          :
        </div>
        <div className={styles.cellTimer}>
          <div className={styles.nameCell}>hours</div>
          <div>00</div>
        </div>
        <div className={styles.colon}>
          :
        </div>
        <div className={styles.cellTimer}>
          <div className={styles.nameCell}>minutes</div>
          <div>00</div>
        </div>
        <div className={styles.colon}>
          :
        </div>
        <div className={styles.cellTimer}>
          <div className={styles.nameCell}>seconds</div>
          <div>00</div>
        </div>
      </div>
      <img className={styles.line} src={gradientLine} alt={''} />
      <div className={styles.areaDescriptionBox}>
        <div className={styles.amountBox}>15,010 mystery boxes</div>
        <div className={styles.typesOfBox}>1 is inside each box:<br /> land, licence, house</div>
        <div className={styles.peculiarity}>mint now at a special price</div>
        <div className={styles.right}>
          <div className={styles.perBox}>per box</div>
          <div className={styles.price}>$70</div>
        </div>
      </div>
      <div className={styles.button} onClick={() => {
        modal.open(<ModalMysteryBox />)
      }}>
        <div className={styles.mintOn}>price now</div>
        <div className={styles.priceInButton}>70 usdt</div>
      </div>
      <div className={styles.blockWithPrice}>
        <div className={styles.priceAmount}>
          ${collectedMoney}
        </div>
        <div className={styles.descriptionPriceAmount}>
          out of $130.000 collected
        </div>
      </div>
      <div className={styles.descriptionProgressBar}>
        <div>pre-seed</div>
        <div className={styles.progress}>{((countNft / AMOUNT_NFT_BOXES) * 100).toFixed(1)}% COMPLETE</div>
      </div>
      {window.innerWidth >= 773 ?
        <div className={styles.progressBar}>
          <div className={styles.scale} style={{ width: `${(countNft / AMOUNT_NFT_BOXES) * 352}px` }}></div>
          <img src={borderForBarInBoxInformationArea} alt={''} className={styles.bord} />
        </div> :
        <div className={styles.progressBar}>
          <div className={styles.scale} style={{ width: `${(countNft / AMOUNT_NFT_BOXES) * 289}px` }}></div>
          <img src={borderForProgressBarMobileVersion} alt={''} className={styles.bord} />
        </div>}
    </div>
  )
}


export default BoxInformationArea;