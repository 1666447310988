import React from "react";
import styles from "./SectionsMission.module.scss";


export const SectionsMission: React.FC = () => {

  return (
    <div className={styles.content}>
      <div className={styles.title}>
        <div className={styles.title__backBlock1}>
          mission
        </div>
        <div className={styles.title__backBlock2}/>
      </div>
      <div className={styles.text}>
        <div className={styles.text__left}>
          <svg width="33" height="182" viewBox="0 0 33 182" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="33" y1="3.5" x2="2.00001" y2="3.5" stroke="#E74FF1" strokeWidth="7"/>
            <line x1="3.50001" y1="8.17705e-08" x2="3.49999" y2="181.032" stroke="#E74FF1" strokeWidth="7"/>
            <line x1="1.00001" y1="177.5" x2="33" y2="177.5" stroke="#E74FF1" strokeWidth="7"/>
            <line x1="33" y1="8" x2="8.57576" y2="8" stroke="#B5E627" strokeWidth="4"/>
            <line x1="9.00001" y1="6" x2="8.99999" y2="175" stroke="#B5E627" strokeWidth="4"/>
            <line x1="7.78788" y1="172.906" x2="33" y2="172.906" stroke="#B5E627" strokeWidth="4"/>
          </svg>
        </div>
        <div className={styles.text__center}>
          Pixie mission is to build a utility metaverse full of income opportunities for all people based on their
          market vision and opinion. We strive to make games where even if you were not lucky with the game outcome, a
          compensatory opportunity will open for you.
          It will be done by Implementing Stay2Earn, Play2Earn, Own2Earn, Refer2Earn, Delegate2Earn mechanisms that will
          allow you to reveal yourself and your talents.
        </div>
        <div className={styles.text__right}>
          <svg width="33" height="182" viewBox="0 0 33 182" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="-3.5" x2="31" y2="-3.5" transform="matrix(1 4.6726e-08 1.63565e-07 -1 0 0)" stroke="#E74FF1"
                  strokeWidth="7"/>
            <line y1="-3.5" x2="181.032" y2="-3.5" transform="matrix(8.17826e-08 1 1 -2.3363e-08 33 0)" stroke="#E74FF1"
                  strokeWidth="7"/>
            <line y1="-3.5" x2="32" y2="-3.5" transform="matrix(-1 0 0 1 32 181)" stroke="#E74FF1" strokeWidth="7"/>
            <line y1="-2" x2="24.4242" y2="-2" transform="matrix(1 5.93061e-08 1.2887e-07 -1 0 6)" stroke="#B5E627"
                  strokeWidth="4"/>
            <line y1="-2" x2="169" y2="-2" transform="matrix(8.17826e-08 1 1 -2.3363e-08 26 6)" stroke="#B5E627"
                  strokeWidth="4"/>
            <line y1="-2" x2="25.2121" y2="-2" transform="matrix(-1 0 0 1 25.2121 174.906)" stroke="#B5E627"
                  strokeWidth="4"/>
          </svg>
        </div>
      </div>
      <div className={styles.flare}>
        <svg width="1440" height="1287" viewBox="0 0 1440 1287" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_f_202_5)">
            <circle cx="528.5" cy="611.5" r="161.5" fill="#B5E627" fill-opacity="0.5"/>
          </g>
          <g filter="url(#filter1_f_202_5)">
            <circle cx="857.5" cy="675.5" r="161.5" fill="#E751BD" fill-opacity="0.5"/>
          </g>
          <defs>
            <filter id="filter0_f_202_5" x="-83" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_202_5"/>
            </filter>
            <filter id="filter1_f_202_5" x="246" y="64" width="1223" height="1223" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_202_5"/>
            </filter>
          </defs>
        </svg>

      </div>


    </div>
  );
};

