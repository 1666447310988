import React, {useContext} from "react";
import styles from "./descriptionAndOpenMysteryBox.module.scss";
import mysteryBox from "../../../../assets/image/imgForOpenMysteryBoxPage/mysteryBoxWithoutShadow.png"
import {ButtonFormHexagonPurpleBlue} from "../../../ButtonFormHexagonPurpleBlue/ButtonFormHexagonPurpleBlue";
import bnbIcon from '../../../../assets/imageBox/Bnb.png'
import {modal} from "../../ModalConnector";
import {OpenNftModal} from "../OpenNftModal/OpenNftModal";
import {getEthereumAccountInfo, IEthereumAccountInfo} from "../../../../utills/metamask/metamask";
import {ethers} from "ethers";
import {ADDRESS_CONTRACT_NFT, NET} from "../../../../config/config";
import abiContractNft from "../../../../openMysteryBoxPage/sectionMysteryBoxes/abiNftContract.json";
import {toast} from "react-toastify";
import {ModalWait} from "../modalWait/modalWait";
import {Context} from "../../../../index";
import {ModalWaitConfirmTrans} from "../modalWaitConfirmTrans/modalWaitConfirmTrans";
import {CHAIN_SETTINGS} from "../../../../utills/metamask/networkParams";

export const DescriptionAndOpenMysteryBox: React.FC<{ idPack: number }> = ({idPack}) => {
  const {storeUser} = useContext(Context);

  const unpackNftBox = async () => {
    try {
      modal.open(<ModalWaitConfirmTrans/>, {className: 'none'});
      const ethereumAccount: IEthereumAccountInfo = await getEthereumAccountInfo(NET === 'mainnet' ? CHAIN_SETTINGS.BNB_MAINNET.id : 97) as IEthereumAccountInfo;
      const NftBoxContract = new ethers.Contract(ADDRESS_CONTRACT_NFT, abiContractNft, ethereumAccount.signer);
      const tx = await NftBoxContract.unpackBox(idPack);
      modal.open(<ModalWait/>, {className: 'none'});
      const txResponse = await tx.wait();
      console.log('res trans open nft ', txResponse, tx);
      if (txResponse.status === 1) {
        modal.open(<OpenNftModal idPack={idPack}/>, {className: 'none'});
        storeUser.isOenNft = !storeUser.isOenNft;
      } else {
        modal.close();
        toast.error("error open box, transaction failed");
      }
    } catch (error) {
      modal.close();
      toast.error("Something went wrong");
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.rightSide}>
        <img src={mysteryBox} alt={''}/>
      </div>
      <div className={styles.leftSide}>
        <div className={styles.title}>mystery box</div>
        <div className={styles.typeCollection}>collection: pixie metaverse collection</div>
        <div className={styles.titleNumber}>title: #7873</div>
        <div className={styles.description}>This is the very first collection of Pixie NFTs which is released prior to
          the metaverse composition. This collection will be the most valuable as it defines the first supporters of the
          project. This collection will also be most profitable.
        </div>
        <div className={styles.blockchain}>
          <img src={bnbIcon} alt={''}/>
          minted on bsc blockchain
        </div>
        <ButtonFormHexagonPurpleBlue className={styles.button} onClick={async () => {
          unpackNftBox();
        }}>open nft</ButtonFormHexagonPurpleBlue>
      </div>
    </div>
  )
};


