import { Footer } from "./components/Footer/Footer"
import { Header } from "./components/Header/Header"
import { Line1 } from "./components/LineBoxPage/Line1/Line1"
import { Line2 } from "./components/LineBoxPage/Line2/Line2"
import { Line3 } from "./components/LineBoxPage/Line3/Line3"
import { Line4 } from "./components/LineBoxPage/Line4/Line4"

import { Line5 } from "./components/LineBoxPage/Line5/Line5"
import { SocialNetworksRightSide } from "./components/SocialNetworksRightSide/SocialNetworksRightSide"
import { MysteryBoxAdvantages } from "./sectionsMysteryBoxPage/MysteryBoxAdvantages/MysteryBoxAdvantages"
import { NftHousesAre } from "./sectionsMysteryBoxPage/NftHousesAre/NftHousesAre"
import { NftLicenceIsRarest } from "./sectionsMysteryBoxPage/NftLicenceIsRarest/NftLicenceIsRarest"
import { OwningLandIn } from "./sectionsMysteryBoxPage/OwningLandIn/OwningLandIn"
import { PixieNft } from "./sectionsMysteryBoxPage/PixieNft/PixieNft"
import { PreFooter } from "./sectionsMysteryBoxPage/PreFooter/PreFooter"
import { SectionSpecialPrizes } from "./sectionsMysteryBoxPage/SectionSpecialPrizes/SectionSpecialPrizes"
import { WhatCanYouFind } from "./sectionsMysteryBoxPage/WhatCanYouFind/WhatCanYouFind"

export const MysteryBoxPage = () => {
  return <div className="App">
    <SocialNetworksRightSide />
    <div className='container'>
      <Header isOpenPage={true} />
    </div>
    <div className='container'>
      <PixieNft />
    </div>
    {/* <div className='lineImformAboutBoxes'>
      <LineInformAboutBoxes/>
    </div> */}
    <Line1 />
    <div className='container'>
      <MysteryBoxAdvantages />
    </div>
    <Line2 />
    <div className='container'>
      <WhatCanYouFind />
    </div>
    <Line3 />
    <div className='container'>
      <SectionSpecialPrizes />
    </div>
    <Line1 />
    <div className='container'>
      <OwningLandIn />
    </div>
    <Line4 />
    {/* <div className='lineGreen' /> */}
    <div className='container'>
      <NftHousesAre />
    </div>

    <Line5 />

    <div className='container'>
      <NftLicenceIsRarest />
    </div>
    {/* <div className='container'> */}
    <PreFooter />
    {/* </div> */}
    <Footer />
  </div>
}