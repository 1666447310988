import {Header} from "../../components/Header/Header";
import {Footer} from "../../components/Footer/Footer";
import {SectionOpenYourMysteryBox} from "../sectionOpenYourMysteryBox/sectionOpenYourMysteryBox";
import {SectionMysteryBoxes} from "../sectionMysteryBoxes/sectionMysteryBoxes";
import {observer} from "mobx-react-lite";
import {SocialNetworksRightSide} from "../../components/SocialNetworksRightSide/SocialNetworksRightSide";


const OpenMysteryBoxPage = () => {
  return <div className="App">
    <SocialNetworksRightSide/>
    <div className='container'>
      <Header isOpenPage={true}/>
    </div>
    <div>
      <SectionOpenYourMysteryBox/>
      <SectionMysteryBoxes/>
    </div>
    <Footer/>
  </div>
}

export default observer(OpenMysteryBoxPage);