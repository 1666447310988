import React from "react";
import styles from "./SectionsRevolutionry.module.scss";
import cl from "classnames";
import img from '../../assets/image/JoinPixie.png'
import imgMobile from '../../assets/image/JoinPixieMobile.png'
import flare from '../../assets/image/flares/flareRevolutionry.png'

export const SectionsRevolutionry: React.FC = () => {

  return (
    <div className={styles.content}>
      <div className={styles.title}>
        revolutionary economy
      </div>
      <div className={styles.blocks}>
        <div className={styles.blocks1}>
          <div className={styles.block}>
            have fun & make real money
          </div>
          <div className={styles.block}>
            your opinion is your tool for earning
          </div>
          <div className={styles.block}>
            passive & active income
          </div>
        </div>
        <div className={styles.blocks1}>
          <div className={styles.block}>
            no losses with compensatory mechanism
          </div>
          <div className={styles.block}>
            unlimited opportunities for creativity
          </div>
          <div className={cl(styles.block, styles.decorate)}>
            decorate your homes& invite others to join
          </div>
        </div>
      </div>
      <div className={styles.img}>
        <img src={img} alt=""/>
      </div>
      <div className={styles.img__mobile}>
        <img src={imgMobile} alt=""/>
      </div>
      <div className={styles.flare}>
        <img src={flare} alt=""/>
      </div>
    </div>
  );
};

