export enum CHAIN_IDs {
  velasTestnet = 111,
  velas = 106,
  bnb = 56,
  bnbTestnet = 97,
  avax = 43114,
  eth = 1,
  skale = 647426021,
}

export const AVALANCHE_TESTNET_PARAMS = {
  chainId: "0xA869",
  chainName: "Avalanche Testnet C-Chain",
  nativeCurrency: {
    name: "Avalanche",
    symbol: "AVAX",
    decimals: 18,
  },
  rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
  blockExplorerUrls: ["https://testnet.snowtrace.io/"],
};

export const AVALANCHE_MAINNET_PARAMS = {
  chainId: "0x" + Number(CHAIN_IDs.avax).toString(16),
  rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
  chainName: "Avalanche",
  nativeCurrency: {
    name: "Avalanche",
    symbol: "AVAX",
    decimals: 18,
  },
  blockExplorerUrls: ["https://snowtrace.io/"],
};

export const VELAS_PARAMS = {
  chainId: "0x" + Number(CHAIN_IDs.velas).toString(16),
  rpcUrls: ["https://evmexplorer.velas.com/rpc"],
  chainName: "Velas Network",
  nativeCurrency: {
    name: "Velas",
    symbol: "VLX",
    decimals: 18,
  },
  blockExplorerUrls: ["https://evmexplorer.velas.com/rpc"],
};

export const VELAS_TESTNET_PARAMS = {
  chainId: "0x" + Number(CHAIN_IDs.velasTestnet).toString(16),
  rpcUrls: ["https://testnet.velas.com/rpc"],
  chainName: "Velas Testnet",
  nativeCurrency: {
    name: "Velas",
    symbol: "VLX",
    decimals: 18,
  },
  blockExplorerUrls: ["https://evmexplorer.testnet.velas.com"],
};

export const BNB_PARAMS = {
  chainId: "0x" + Number(CHAIN_IDs.bnb).toString(16),
  rpcUrls: ["https://bsc-dataseed.binance.org/"],
  chainName: "Smart Chain",
  nativeCurrency: {
    symbol: "BNB",
    decimals: 18,
  },
  blockExplorerUrls: ["https://bscscan.com"],
};

export const BNB_TESTNET_PARAMS = {
  chainId: "0x" + Number(CHAIN_IDs.bnbTestnet).toString(16),
  rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
  chainName: "Smart Chain - Testnet",
  nativeCurrency: {
    symbol: "BNB",
    decimals: 18,
  },
  blockExplorerUrls: ["https://testnet.bscscan.com"],
};

export const SKALE_PARAMS = {
  chainId: "0x" + Number(CHAIN_IDs.skale).toString(16),
  chainName: "SKALE Network Testnet",
  rpcUrls: ["https://eth-online.skalenodes.com/v1/hackathon-complex-easy-naos"],
  nativeCurrency: {
    name: "SKALE FUEL",
    symbol: "sFUEL",
    decimals: 18,
  },
  blockExplorerUrls: [
    "https://expedition.dev/?network=SKALE&rpcUrl=" +
    "https://eth-online.skalenodes.com/v1/hackathon-complex-easy-naos",
  ],
};

export const CHAIN_SETTINGS = {
  VELAS_MAINNET: {
    id: 106,
    params: VELAS_PARAMS,
  },
  VELAS_TESTNET: {
    id: 111,
    params: VELAS_TESTNET_PARAMS,
  },
  ETH_MAINNET: {
    id: 1,
    params: {},
  },
  ETH_TESTNET: {
    id: 5,
    params: {},
  },
  BNB_MAINNET: {
    id: 56,
    params: BNB_PARAMS,
  },
  BNB_TESTNET: {
    id: 97,
    params: BNB_TESTNET_PARAMS,
  },
  AVAX_MAINNET: {
    id: 43114,
    params: AVALANCHE_MAINNET_PARAMS,
  },
  AVAX_TESTNET: {
    id: 97,
    params: BNB_TESTNET_PARAMS,
  },
};
