import React from "react";

export const SvgArrowR: React.FC = () => {
  return (
    <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 24L10 14L0 4L2 0L16 14L2 28L0 24Z" fill="#F8F8F8"/>
    </svg>

  );
};
