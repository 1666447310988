import styles from "./Line3.module.scss";


export const Line3: React.FC = () => {

  return (
    <div className={styles.blockLine1}>
      <div className={styles.protrusion}/>
    </div>
  );
};

