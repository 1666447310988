import React from "react";
import styles from "./SectionsNoLoss.module.scss";
import cl from "classnames";
import {SvgCoinsHome} from "../../components/svg/SectionsWhatAre/SvgCoinsHome";
import {SvgCoin} from "../../components/svg/SectionsWhatAre/SvgCoin";

export const SectionsNoLoss: React.FC = () => {

  return (
    <div className={styles.block}>
      <div className='container'>
        <div className={styles.content}>
          <div className={styles.title}>
            no-loss game
          </div>
          <div className={styles.subtitle}>
            Monetize your opinion & get financial freedom
          </div>
          <div className={styles.img}>
            <SvgCoinsHome/>
            <div className={cl(styles.coin, styles.coin1)}>
              <SvgCoin/>
            </div>
            <div className={cl(styles.coin, styles.coin2)}>
              <SvgCoin/>
            </div>
            <div className={cl(styles.coin, styles.coin3)}>
              <SvgCoin/>
            </div>
            <div className={cl(styles.coin, styles.coin4)}>
              <SvgCoin/>
            </div>
            <div className={cl(styles.coin, styles.coin5)}>
              <SvgCoin/>
            </div>
            <div className={cl(styles.coin, styles.coin6)}>
              <SvgCoin/>
            </div>
            <div className={cl(styles.coin, styles.coin7)}>
              <SvgCoin/>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

