import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import googlePlay from '../../assets/googlePlay.svg';
import btn from '../../assets/image/BtnMenu.png';
import newPoster from '../../assets/image/NewHeader.svg';
import { NET } from "../../config/config";
import { Context } from "../../index";
import { getEthereumAccountInfo, IEthereumAccountInfo } from "../../utills/metamask/metamask";
import { CHAIN_SETTINGS } from "../../utills/metamask/networkParams";
import { ButtonFormHexagonPurpleBlue } from "../ButtonFormHexagonPurpleBlue/ButtonFormHexagonPurpleBlue";
import { Logo } from "../Logo/Logo";
import { SocialNetworks } from "../SocialNetworks/SocialNetworks";
import { SvgHeart } from "../svg/Header/SvgHeart";
import { SvgIconCloseMenu } from "../svg/SvgIconCloseMenu";
import { SvgMenu } from "../svg/SvgMenu";
import styles from "./Header.module.scss";

export const Header: React.FC<{ isOpenPage?: boolean }> = observer(({ isOpenPage }) => {

  const { storeUser } = useContext(Context);
  const [isOpenMenu, setisOpenMenu] = useState(false);

  const login = async () => {
    console.log("connect button click")
    const eth: IEthereumAccountInfo = await getEthereumAccountInfo(
      NET === 'mainnet' ?
        CHAIN_SETTINGS.BNB_MAINNET.id
        :
        97
    ) as IEthereumAccountInfo;
    console.log("===========================")
    console.log(eth)
    console.log(eth?.walletAddress)
    console.log(eth.walletAddress)
    console.log(storeUser.isAuth)
    console.log("===========================")
    // const eth = await getInfoMetaMask()
    if (eth?.walletAddress) {
      storeUser.login(eth.walletAddress)
    }

  }

  const navigate = useNavigate();
  return (
    <header>
      <div className={styles.left}>
        <div onClick={() => {
          navigate('/')
        }}>
          <Logo />
        </div>

        <div className={styles.tabs}>
          <a href="#about" onClick={() => {
            navigate('/')
          }}>
            About
          </a>
          <a className={styles.__mobile} onClick={() => {
            navigate('/nft')
          }}>
            {/* href="#nft" */}
            nft
            <img className={styles.newPoster} src={newPoster} alt="" />
          </a>
          <a href="#ecosystem" onClick={() => {
            navigate('/')
          }}>
            ecosystem
          </a>
          <a href="#tokenomics" onClick={() => {
            navigate('/')
          }}>
            Tokenomics
          </a>
          <a href="#roadmap" onClick={() => {
            navigate('/')
          }}>
            Roadmap
          </a>
          <a className={styles.googlePlay} onClick={() => {
            window.open("https://play.google.com/store/apps/details?id=justapps.studios.pixie&pli=1", "_blank");
          }}>
            Mobile app
            <img src={googlePlay} height={30} alt="" />
          </a>
          <a onClick={() => {
            navigate(('/open_box'))
          }}>
            my nfts
          </a>


        </div>
      </div>
      {!isOpenPage &&
        <div className={styles.right}>
          <div className={styles.hearts}>
            <div className={styles.hearts1}>
              <SvgHeart />
            </div>
            <div className={styles.hearts2}>
              <SvgHeart />
            </div>
            <div className={styles.hearts3}>
              <SvgHeart />
            </div>
          </div>
        </div>}
      {isOpenPage &&
        <>
          <div className={styles.rightHearts}>
            <div className={styles.hearts}>
              <div className={styles.hearts1}>
                <SvgHeart />
              </div>
              <div className={styles.hearts2}>
                <SvgHeart />
              </div>
              <div className={styles.hearts3}>
                <SvgHeart />
              </div>
            </div>
          </div>
          <div className={styles.walletConnect}>
            {storeUser.isAuth ?
              <>
                <div className={styles.addressConnect}>{storeUser.wallet.slice(0, 6)}...</div>
                <ButtonFormHexagonPurpleBlue className={styles.buttonDisconnect} onClick={() => {
                  storeUser.logout();
                  console.log(storeUser.isAuth)
                }}>disconnect</ButtonFormHexagonPurpleBlue>
              </>
              :
              <>
                <ButtonFormHexagonPurpleBlue className={styles.buttonDisconnect} onClick={() => {
                  login()
                }}>connect</ButtonFormHexagonPurpleBlue>
              </>
            }
          </div>
        </>}
      <div className={styles.menu} onClick={() => {
        setisOpenMenu(true)
      }}>
        <SvgMenu />
      </div>
      {isOpenMenu &&
        <div className={styles.menu_block}>
          <div className={styles.close} onClick={() => {
            setisOpenMenu(false)
          }}>
            <SvgIconCloseMenu />
          </div>
          <div className={styles.tabs__mobile}>
            <a href="#about" onClick={() => {
              navigate('/');
              setisOpenMenu(false);
            }}>
              About
            </a>
            <a className={styles.__mobile} onClick={() => {
              navigate('/nft');
              setisOpenMenu(false);
            }}>
              {/* href="#nft" */}
              nft
              <img className={styles.newPoster} src={newPoster} alt="" />
            </a>
            <a href="#ecosystem" onClick={() => {
              navigate('/');
              setisOpenMenu(false);
            }}>
              ecosystem
            </a>
            <a href="#tokenomics" onClick={() => {
              navigate('/');
              setisOpenMenu(false);
            }}>
              Tokenomics
            </a>
            <a href="#roadmap" onClick={() => {
              navigate('/');
              setisOpenMenu(false);
            }}>
              Roadmap
            </a>
            <a className={styles.googlePlay} onClick={() => {
              window.open("https://play.google.com/store/apps/details?id=justapps.studios.pixie&pli=1", "_blank");
              setisOpenMenu(false);
            }}>
              Mobile app
              <img src={googlePlay} height={30} alt="" />
            </a>
            <a onClick={() => {
              navigate(('/open_box'));
              setisOpenMenu(false);
            }}>
              my nfts
            </a>

            {storeUser.isAuth ?
              <>
                <div className={styles.addressConnectMob}>{storeUser.wallet.slice(0, 6)}...</div>
                <ButtonFormHexagonPurpleBlue className={styles.buttonDisconnectMob} onClick={() => {
                  storeUser.logout();

                  console.log(storeUser.isAuth)
                }}>disconnect</ButtonFormHexagonPurpleBlue>
              </>
              :
              <>
                <ButtonFormHexagonPurpleBlue className={styles.buttonDisconnect} onClick={() => {
                  login();

                }}>connect</ButtonFormHexagonPurpleBlue>
              </>
            }
            <SocialNetworks />
            <div className={styles.btn} onClick={() => {
              window.open("https://t.me/pixie_project_bot", "_blank")
            }}>
              <img src={btn} alt="" />
            </div>

          </div>
        </div>
      }

    </header>
  );
})

