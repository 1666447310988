import React, { useContext } from "react";

import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import newText from '../../assets/image/New.svg';
import box from '../../assets/image/imgForOpenMysteryBoxPage/mysteryBox.png';
import { Context } from "../../index";
import styles from "./SectionNftSale.module.scss";


export const SectionNftSale: React.FC = observer(() => {
  const navigate = useNavigate();
  const { storeUser } = useContext(Context);

  return (
    <div className={styles.content}>
      <div className={styles.title}>
        <div className={styles.title__text}>
          nft sale
          <img src={newText} alt="" />
        </div>

      </div>
      <div className={styles.Maintitle}>
        reinventing nfts
      </div>
      <div className={styles.center}>
        <div className={styles.left}>
          <div className={styles.leftTitle}>
            monetize your opinion and let it earn for you
          </div>
          <div className={styles.leftText}>
            Take part in the NFT sale and secure your long-life fully passive income. Every NFT in the drop will let you
            earn money from the platform games pools, without anything else from your end.
          </div>
          <div className={styles.dots}>
            <div className={styles.item}>
              <div className={styles.dot} />
              NFTs are limited.

            </div>
            <div className={styles.item}>
              <div className={styles.dot} />
              Own2Earn with Pixie
            </div>
          </div>
          {/* {!storeUser.isPaymentNewBox ?
            <ButtonFormHexagonPurpleBlue className={styles.buttonBuyBox} onClick={() => {
              modal.open(<ModalMysteryBox />);
              storeUser.isPaymentNewBox = true;
            }}>buy a box</ButtonFormHexagonPurpleBlue> :
            <ButtonFormHexagonPurpleBlue className={styles.buttonBuyBox} onClick={() => {
              toast('wait for the previous payment to be completed')
            }}>buy a box</ButtonFormHexagonPurpleBlue>
          } */}
        </div>
        <div className={styles.right}>
          {/* <div className={styles.rightRound}>PIX1 Round1/5</div> */}
          <div className={styles.rightImg}>
            <img src={box} alt="" className={styles.box} />
          </div>
        </div>
      </div>
      {/* <div className={styles.wProgressBar}>
        <ProgressBarNft className={styles.progressBar} />
      </div> */}
    </div>
  );
});

