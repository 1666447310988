import React from "react";
import styles from "./sectionOpenYourMysteryBox.module.scss";
import bgShadow from "../../assets/image/imgForOpenMysteryBoxPage/shadowForPageOpenMysteryBox.png";
import mysteryBox from "../../assets/image/imgForOpenMysteryBoxPage/mysteryBoxWithoutShadow.png";
import lock from "../../assets/image/imgForOpenMysteryBoxPage/lock.png";
import questionMark from "../../assets/image/imgForOpenMysteryBoxPage/questionMark.png";
import arrow from "../../assets/image/imgForOpenMysteryBoxPage/arrow.png";
import inscription from "../../assets/image/imgForOpenMysteryBoxPage/insription.png";
import textMysteryBox from "../../assets/image/imgForOpenMysteryBoxPage/textMysteryBox.png"

export const SectionOpenYourMysteryBox: React.FC = () => {

  return (
    <div className={styles.content}>
      <div className={styles.title}><img src={textMysteryBox} alt={''}/></div>
      <div className={styles.bgShadow}>
        <img src={bgShadow} alt={''}/>
      </div>
      <div className={styles.areaForBox}>
        <div>
          <div className={styles.inscription}>
            <img src={inscription} alt={''}/>
          </div>
          <img src={lock} alt={''} className={styles.lock}/>
        </div>
        <div className={styles.wImgBox}>
          <img src={mysteryBox} alt={''} className={styles.mysteryBox}/>
        </div>
        <div>
          <img src={arrow} alt={''} className={styles.arrow}/>
          <img src={questionMark} alt={''} className={styles.questionMark}/>
        </div>
      </div>
      <div className={styles.button}>
        your nfts
      </div>
    </div>
  )
};

