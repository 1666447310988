import React from "react";
import { SvgLogoIn } from "../svg/SvgLogoIn";
import { SvgLogoTelegram } from "../svg/SvgLogoTelegram";
import { SvgLogoTwitter } from "../svg/SvgLogoTwitter";
import styles from "./SocialNetworks.module.scss";

export const SocialNetworks: React.FC = () => {
  return (
    <div className={styles.socialNetworks}>
      <div className={styles.icon} onClick={() => {
        window.open("https://t.me/pixie_updates", "_blank")
      }}>
        {<SvgLogoTelegram />}
      </div>
      <div className={styles.icon} onClick={() => {
        window.open("https://twitter.com/Pixie_News", "_blank")
      }}>
        {<SvgLogoTwitter />}
      </div>
      {/* <div className={styles.icon} onClick={() => {
        window.open("https://discord.gg/thBHWXvdve", "_blank")
      }}>
        {<SvgLogoDiscord/>}
      </div> */}
      <div className={styles.icon} onClick={() => {
        window.open("https://www.linkedin.com/company/pixiefun/", "_blank")
      }}>
        {<SvgLogoIn />}
      </div>
    </div>
  );
};

