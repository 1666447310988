import React from "react";
import styles from "./Footer.module.scss";
import cl from "classnames";
import {SvgLogo} from "../svg/Header/SvgLogo";
import {SocialNetworks} from "../SocialNetworks/SocialNetworks";
import {useNavigate} from "react-router-dom";

export const Footer: React.FC = () => {
  const navigate = useNavigate();
  const renderColumn1 = () => {
    return <div className={styles.tabs}>
      <div className={styles.tabs__Name}>
        Pixie World
      </div>
      <a href="#" className={styles.tab} onClick={() => navigate('/')}>
        Home
      </a>
      <a href="#mission" className={styles.tab} onClick={() => navigate('/')}>
        Mission
      </a>
      <a href="#ecosystem" className={styles.tab} onClick={() => navigate('/')}>
        Ecosystem
      </a>
      <a href="#tokenomics" className={styles.tab} onClick={() => navigate('/')}>
        Economy
      </a>
      <a href="#" className={styles.tab} onClick={() => navigate('/')}>
        Partners
      </a>
      <a className={styles.tab} onClick={() => {
        window.open("https://play.google.com/store/apps/details?id=justapps.studios.pixie&pli=1", "_blank");
      }}>
        Mobile app
      </a>
      <a className={styles.tab__mobile} onClick={() => {
        window.open("https://docs.pixie.fun", "_blank");
      }}>
        Whitepaper
      </a>
    </div>
  }
  const renderColumn2 = () => {
    return <div className={styles.tabs}>
      <div className={styles.tabs__Name}>
        Mechanics
      </div>
      <a href="#noLoss" className={styles.tab} onClick={() => navigate('/')}>
        No-loss game
      </a>
      <a href="#tokenomics" className={styles.tab} onClick={() => navigate('/')}>
        Tokenomics
      </a>
      <a href="#roadmap" className={styles.tab} onClick={() => navigate('/')}>
        Roadmap
      </a>
      <a href="#staking" className={styles.tab} onClick={() => navigate('/')}>
        Staking
      </a>
      <a className={styles.tab} onClick={() => {
        window.open("https://www.dropbox.com/s/vaqt5mk0dxezixo/Pixie%20Deck.pdf?dl=0", "_blank");
      }}>
        Pixie Deck
      </a>
      {/* <a href="#" className={styles.tab__mobile}>
                Terms of Use
            </a> */}
    </div>
  }
  const renderColumn3 = () => {
    return <div className={cl(styles.tabs, styles.column3)}>
      <div className={styles.tabs__Name}>
        Resources
      </div>
      <a className={styles.tab} onClick={() => {
        window.open("https://docs.pixie.fun", "_blank");
      }}>
        Whitepaper
      </a>
      {/* <a href="#" className={styles.tab}>
                Terms of Use
            </a> */}
      <div className={styles.ContactUs}>Contact us</div>
      <SocialNetworks/>
      {/* <div className={styles.email}>
                email@email.com
            </div> */}
    </div>
  }
  const renderMobileContact = () => {
    return <div className={cl(styles.tabs, styles.column3__mobile)}>
      {/* <SocialNetworks /> */}
      {/* <div className={styles.ContactUs}>Contact us: </div> */}
      <SocialNetworks/>
    </div>
  }
  return (
    <footer>
      <div className="container">
        <div className={styles.content}>
          <a href="#" className={styles.logo}>
            <SvgLogo/>
          </a>
          {renderMobileContact()}
          <div className={styles.content__tabs}>
            {renderColumn1()}
            {renderColumn2()}
            {renderColumn3()}
          </div>

        </div>
      </div>
    </footer>
  );
};

