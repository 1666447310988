import styles from "./Logo.module.scss";
import {SvgLogo} from "../svg/Header/SvgLogo";

export const Logo: React.FC = () => {

  return (
    <a href="#" className={styles.logo}>
      <SvgLogo/>
    </a>
  );
};

