import React, {useContext} from "react";
import styles from "./WhatCanYouFind.module.scss";
import img1 from '../../assets/imageBox/I1Block3.png';
import img2 from '../../assets/imageBox/I2Block3.png';
import img3 from '../../assets/imageBox/I3Block3.png';
import box from '../../assets/imageBox/boxWithSequins.png';
import cl from "classnames";
import {ModalMysteryBox} from "../../components/modal/components/ModalMysteryBox/ModalMysteryBox";
import {modal} from "../../components/modal";
import {toast} from "react-toastify";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {ButtonFormHexagonPurpleBlue} from "../../components/ButtonFormHexagonPurpleBlue/ButtonFormHexagonPurpleBlue";
import line from "../../assets/imageBox/lineForPageNft.png";

export const WhatCanYouFind: React.FC = observer(() => {
  const {storeUser} = useContext(Context);

  return (
    <div className={styles.content}>
      <div className={styles.subTitleBlock}>
        <div className={styles.subTitle}>
          what can you find in the mystery box?
        </div>
      </div>
      <div className={styles.center}>
        <div className={styles.items}>
          <div className={cl(styles.item, styles.img1)}>
            <div className={styles.itemTitle}>
              land
            </div>
            <img src={img1} alt=""/>
          </div>
          <div className={cl(styles.item, styles.item2)}>
            <div className={styles.itemTitle}>
              house
            </div>
            <img src={img2} alt="" className={styles.houseImg}/>
          </div>
          <div className={cl(styles.item)}>
            <div className={styles.itemTitle}>
              licence
            </div>
            <img src={img3} alt=""/>
          </div>

        </div>
      </div>
      <div className={styles.dots}>
        <div className={styles.item}>
          <div className={styles.dot}/>
          absolutely unique
        </div>
        <img src={line} alt={''} className={styles.line1}/>
        <div className={styles.item}>
          <div className={styles.dot}/>
          6 exclusivity rangs - randomly distributed
        </div>
        <img src={line} alt={''} className={styles.line2}/>
        <div className={styles.item}>
          <div className={styles.dot}/>
          minted on binance blockchain
        </div>
        <img src={line} alt={''} className={styles.line3}/>
        <div className={styles.item}>
          <div className={styles.dot}/>
          <div className={styles.itemText}>
            Every platform game prize pool is partially shared across the NFTs and all operations are in USDT
          </div>
        </div>
      </div>
      <div className={styles.btn}>
        {!storeUser.isPaymentNewBox ?
          <ButtonFormHexagonPurpleBlue className={styles.button} onClick={() => {
            modal.open(<ModalMysteryBox/>);
            storeUser.isPaymentNewBox = true;
          }}>buy a box</ButtonFormHexagonPurpleBlue> :
          <ButtonFormHexagonPurpleBlue className={styles.button} onClick={() => {
            toast('wait for the previous payment to be completed')
          }}>buy a box</ButtonFormHexagonPurpleBlue>
        }
      </div>
      <img className={styles.box} src={box} alt=""/>

    </div>
  );
});

