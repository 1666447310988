import React, {useContext, useEffect, useState} from "react";
import styles from "./ModalMysteryBox.module.scss";
import box from '../../../../assets/image/box2.png'
import bnb from '../../../../assets/imageBox/Bnb.png'
import {getEthereumAccountInfo, IEthereumAccountInfo} from "../../../../utills/metamask/metamask";
import {Context} from "../../../..";
import {toast} from "react-toastify";
import {ethers} from "ethers";
import {fetchCheckNftPayment, fetchGetPricePack} from "../../../../api/pay";
import {abi} from "./abi";
import {BNB_RECIPIENT_ADDRESS, BUSD_CONTRACT_ADDRESS, BUSD_RECIPIENT_ADDRESS, NET} from "../../../../config/config";
import {modal} from "../../ModalConnector";
import {marketAbi} from "../../../../config/market";
import {fetchGetProfile} from "../../../../api/profile";
import {ButtonFormHexagonPurpleBlue} from "../../../ButtonFormHexagonPurpleBlue/ButtonFormHexagonPurpleBlue";
import totalPrice from "../../../../assets/image/totalPrice.png";
import blueShadow from "../../../../assets/image/flares/blueShadow.png";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {CHAIN_SETTINGS} from "../../../../utills/metamask/networkParams";

export const ModalMysteryBox: React.FC = () => {
  const {storeUser} = useContext(Context);
  const [price, setPrice] = useState<number>(0);
  const [countBoxes, setCountBoxes] = useState<number>(1);

  const login = async () => {
    const eth: IEthereumAccountInfo = await getEthereumAccountInfo(NET === 'mainnet' ? CHAIN_SETTINGS.BNB_MAINNET.id : 97) as IEthereumAccountInfo;

    console.log(eth)
    // const eth = await getInfoMetaMask()
    if (eth?.walletAddress) {
      storeUser.login(eth.walletAddress)
    }

  }

  const getPrice = async () => {
    try {
      let res = await fetchGetPricePack();
      setPrice(res.data.price)
    }catch (e) {
      setPrice(0.01);
    }
  }

  useEffect(() => {
    login();
    getPrice();

  }, [])

  const purchaseBNB = async () => {

    try {
      modal.close()
      toast.loading("Payment is expected... \nPlease do not refresh the page!");
      console.log(BNB_RECIPIENT_ADDRESS);
      const ethereumAccount: IEthereumAccountInfo = await getEthereumAccountInfo(NET === 'mainnet' ? CHAIN_SETTINGS.BNB_MAINNET.id : 97) as IEthereumAccountInfo;
      const walletForPayment = await fetchGetProfile();
      const params = [{
        from: ethereumAccount.walletAddress,
        to: walletForPayment.data.addr,
        value: ethers.utils.parseUnits(String(price * countBoxes), 'ether').toHexString()
      }];
      const transactionHash = await ethereumAccount.provider.send('eth_sendTransaction', params);
      console.log('transactionHash is ' + transactionHash);
      // console.log(ethereumAccount.signer.checkTransaction(transactionHash))
      ethereumAccount.provider.waitForTransaction(transactionHash).then(async function (transaction) {
        console.log(transaction);
        const res = await fetchCheckNftPayment(countBoxes);
        console.log('result payment ', res.data.result)
        toast.dismiss();
        if (res.data.result) {
          storeUser.isPaymentNewBox = false;
          toast.success("Success.");
          storeUser.setProfileData();

        } else {
          storeUser.isPaymentNewBox = false;
          toast.error("Something went wrong");
        }
      }).catch(() => {
        toast.error("Something went wrong");
        storeUser.isPaymentNewBox = false;
      });


    } catch (error) {
      storeUser.isPaymentNewBox = false;
      toast.dismiss();
      toast.error("Something went wrong");
      console.log("error" + error)
    }

  }

  const purchaseBUSD = async () => {
    modal.close()
    toast.loading("Payment is expected...");
    try {
      const amountInTheSmallestUnit = ethers.utils.parseUnits(price.toString(), 'ethers')
      const ethereumAccount: IEthereumAccountInfo = await getEthereumAccountInfo(NET === 'mainnet' ? CHAIN_SETTINGS.BNB_MAINNET.id : 97) as IEthereumAccountInfo;
      const busdContract = new ethers.Contract(BUSD_CONTRACT_ADDRESS, abi, ethereumAccount.signer);
      const tx = await busdContract.transfer(BUSD_RECIPIENT_ADDRESS, amountInTheSmallestUnit);
      const txResponse = await tx.wait();
      console.log(txResponse, tx);
      const res = await fetchCheckNftPayment(countBoxes);
      toast.dismiss();
      if (res.data.result) {
        toast.success("Success.");
        storeUser.setProfileData()
        modal.close()
      } else {
        toast.error("Something went wrong");
      }

    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong");
      console.log("error" + error)
    }

  }
  const getNfts = async () => {
    const ethereumAccount: IEthereumAccountInfo = await getEthereumAccountInfo(NET === 'mainnet' ? CHAIN_SETTINGS.BNB_MAINNET.id : 97) as IEthereumAccountInfo;
    const marketContract = new ethers.Contract(marketAbi.address, marketAbi.abi, ethereumAccount.signer);
    const tx = await marketContract.getMyNFTs();
    console.log(tx)
  }
  useEffect(() => {
    getNfts()
  }, [])


  return (
    <div className={styles.content}>
      <img src={blueShadow} alt={''} className={styles.blueShadow}/>
      <div className={styles.right}>
        <img className={styles.box} src={box} alt=""/>
        <div className={styles.blockchain}>
          <img src={bnb} alt=""/>
          minted on bsc blockchain
        </div>
      </div>
      <div className={styles.left}>
        <div className={styles.preTitle}>
          PIX1 Round1/5
        </div>
        <div className={styles.title}>
          mystery box
        </div>
        <>
          <div className={styles.text}>
            this is a mystery box containing one of the Pixie metaverse assets: pixie nft (50%),
            nft house (20%), nft land (20%), or nft licence (10%). each of them is unique and will bring
            you passive income.
          </div>
          <div className={styles.rangeSection}>
            <div className={styles.wCountBoxes}><span className={styles.countBoxes}>{countBoxes}</span> NFT</div>
            <div className={styles.wInputRange}>
              <Slider
                className={styles.SliderCount}
                step={1}
                min={1}
                max={25}
                defaultValue={1}
                trackStyle={{backgroundColor: '#433F5F', height: 5}}
                handleStyle={{
                  borderColor: 'none',
                  border: 'none',
                  height: 28,
                  width: 28,
                  marginLeft: -14,
                  marginTop: -9,
                  backgroundColor: 'linear-gradient(270.02deg, #4FD4F1 0.02%, #D14FF1 100.02%)',
                }}
                railStyle={{backgroundColor: '#433F5F', height: 5}}
                onChange={(e) => {
                  setCountBoxes(Number(e))
                }}
              />
            </div>
          </div>
          <div className={styles.informAboutPrice}>price: <span className={styles.discountPrice}>${(2000 * countBoxes).toFixed(0)}<div
            className={styles.line}></div></span> ${(70 * countBoxes).toFixed(0)} <span
            className={styles.discount}>(discount  93.5%)</span> x24
          </div>
          <div className={styles.buyBlock}>
            <span className={styles.totalText}>
              <img src={totalPrice} alt={''}/>
              Total:
            </span>
            <span className={styles.priceBoxTotal}>{(price * countBoxes).toFixed(2)} BNB</span>
            <ButtonFormHexagonPurpleBlue onClick={purchaseBNB}
                                         className={styles.button}>mint now</ButtonFormHexagonPurpleBlue>
          </div>
        </>
      </div>
    </div>
  );
};

