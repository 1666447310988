import img from '../../assets/image/riderNoBg.png';
import flare from '../../assets/image/shadowInMainPage.png';
import { ButtonFormHexagonPurpleBlue } from "../../components/ButtonFormHexagonPurpleBlue/ButtonFormHexagonPurpleBlue";
import { SvgMobileTitle } from "../../components/svg/SectionsGetYour/SvgMobileTitle";
import styles from "./SectionsGetYour.module.scss";


export const SectionsGetYour: React.FC = () => {
  return (
    <div className={styles.content}>
      <div className={styles.left}>
        <div className={styles.title}>
          join the pixie world and earn every 5 mins
        </div>
        <div className={styles.title__mobile}>
          <SvgMobileTitle />
        </div>
        <div className={styles.subtitle}>
          Don’t waste your time investing in cryptocurrencies or stocks where the price depends on random factors, but
          invest in events that have only one outcome, “yes” or “no”. Join Pixie and monetize your market vision.
          <div />

        </div>

        <div className={styles.btns}>
          <ButtonFormHexagonPurpleBlue onClick={() => {
            window.open("https://t.me/pixie_project_bot", "_blank")
          }}>Play Pixie</ButtonFormHexagonPurpleBlue>
          {/* <div className={styles.buttonPlayVideo} onClick={() => {
            window.open('https://youtu.be/XbZl3hfbVDc')
          }}>
            <img src={playVideoButton} alt={''}/>
            <span className={styles.textWatchVideo}>watch video</span>
          </div> */}
        </div>
        {/* <button className={styles.join} onClick={() => {
          modal.open(<ModalEmail />)
        }
        }> Join PIX tokensale Waitlist
        </button> */}
      </div>
      <div className={styles.right}>
        <img src={img} alt="" />
      </div>
      <div className={styles.flare}>
        <img src={flare} alt="" />
      </div>

    </div>
  );
};

