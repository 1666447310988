import React from "react";
import img from '../../assets/image/ImageGirl.png';
import img1 from '../../assets/image/ImageGirl__pixels1.png';
import img2 from '../../assets/image/ImageGirl__pixels2.png';
import { ButtonFormHexagonPurpleBlue } from "../../components/ButtonFormHexagonPurpleBlue/ButtonFormHexagonPurpleBlue";
import { SvgSnail } from "../../components/svg/SvgSnail";
import styles from "./SectionsWeCant.module.scss";


export const SectionsWeCant: React.FC = () => {

  return (
    <div className={styles.content}>
      <div className={styles.left}>
        <img src={img} alt="" />
        <img className={styles.pixel1} src={img1} alt="" />
        <img className={styles.pixel2} src={img2} alt="" />
      </div>
      <div className={styles.right}>
        <div className={styles.title}>
          we can’t wait any longer
          <SvgSnail />
        </div>
        <div className={styles.subtitle}>
          <div className={styles.paragraph}>
            Pixie enters 3 emerging markets at once: metaverses, NFT and blockchain games and features a remarkable
            pixel pattern style which is a trend of the upcoming year.
          </div>
          <div className={styles.paragraph}>
            This is a true utility project with a clear and understandable NFT function, token’s role and connection
            with the metaverse. NFT and token ownership will bring users a <b> passive income which will grow as our
              platform grows</b>.
          </div>

        </div>

        <div className={styles.btns}>
          <ButtonFormHexagonPurpleBlue className={styles.greenButton} onClick={() => {
            window.open("https://t.me/pixie_project_bot", "_blank")
          }}> get started</ButtonFormHexagonPurpleBlue>
        </div>

      </div>
    </div>
  );
};

