import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ModalConnector} from './components/modal';
import {BrowserRouter} from 'react-router-dom';
import StoreUser from './store/storeUser';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const storeUser = new StoreUser();

interface State {
  storeUser: StoreUser;

}

export const Context = createContext<State>({storeUser});

root.render(
  <Context.Provider value={{storeUser}}>
    <ToastContainer position="top-center"/>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
    <ModalConnector/>
  </Context.Provider>
);


