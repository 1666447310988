import React from "react";
import styles from "./SlideCard.module.scss";

export const SlideCard: React.FC<{ title: string, text: string }> = ({title, text}) => {
  return (
    <div className={styles.card}>
      <div className={styles.title}>
        <div className={styles.title__text}>
          {title}
        </div>
        <div className={styles.title__block}>
          <svg width="283" height="83" viewBox="0 0 283 83" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M18.3059 0H264.419V18.3059H282.724L282.724 82.3765H0V18.3059H18.3059V0Z" fill="#504A9B"/>
          </svg>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.content__text}>
          {text}
        </div>
      </div>
    </div>
  );
};

