import React from "react";

export const SvgArrowL: React.FC = () => {
  return (
    <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 24L6 14L16 4L14 0L0 14L14 28L16 24Z" fill="#F8F8F8"/>
    </svg>

  );
};
