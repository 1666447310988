import {makeAutoObservable} from "mobx";
import {fetchAuthLogin, fetchAuthLogout, fetchAuthRefresh} from "../api/auth";
import {fetchGetProfile,} from "../api/profile";
import {IProfileInfo} from "../types/profile";

export default class StoreUser {
  isAuth: boolean = false;
  profileInfo: IProfileInfo = {} as IProfileInfo;
  isLoading: boolean = false;
  wallet: any;
  rand: any;
  isLoadingProfile: boolean = false;
  isOenNft: boolean = false;
  isPaymentNewBox: boolean = false;
  isNewNftOpen:boolean=false;

  constructor() {
    makeAutoObservable(this);
    this.wallet = localStorage.getItem("wallet");
  }

  setAuth = (bool: boolean) => {
    this.isAuth = bool;
    console.log("isAuth", this.isAuth);
  };
  setWallet = (accountId: string) => {
    this.wallet = accountId;
  };
  setProfileInfo = (user: IProfileInfo) => {
    this.profileInfo = user;
  };
  setLoading = (bool: boolean) => {
    this.isLoading = bool;
  };

  setProfileData = async () => {
    this.isLoadingProfile = false;
    const res = await fetchGetProfile();
    console.log("res.data", res.data);
    this.wallet = res.data.nickname;
    this.setProfileInfo(res.data);
    console.log("currentUser", this.profileInfo);
    this.isLoadingProfile = true;
  };
  login = async (walletAddress: any) => {
    try {
      this.setWallet(walletAddress);
      console.log("LOGIN accountId", walletAddress);
      const serverResponse = await fetchAuthLogin(walletAddress);
      console.log("прошли fetchAuthLogin")
      console.log(
        "login serverResponse.data.refreshToken",
        serverResponse.data.refreshToken
      );
      console.log("расставили localStorages")
      localStorage.setItem("wallet", walletAddress);
      localStorage.setItem("accessToken", serverResponse.data.accessToken);
      localStorage.setItem("refreshToken", serverResponse.data.refreshToken);
      console.log("авторизовались")
      this.setAuth(true);
      console.log(this.isAuth)
      await this.setProfileData();
    } catch (e) {
      console.log(e);
    } finally {
      this.setLoading(false);
    }
  };

  logout = async () => {
    this.setLoading(true);
    try {
      await fetchAuthLogout();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("typeAuthorization");
      this.setWallet("");
      this.setAuth(false);
      this.setProfileInfo({} as IProfileInfo);
    } catch (e) {
      console.log(e);
    } finally {
      this.setLoading(false);
    }
  };

  checkAuth = async () => {
    this.setLoading(true);
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        return;
      }
      console.log("checkAuth refreshToken", refreshToken);
      const serverResponse = await fetchAuthRefresh(refreshToken);
      console.log("1");
      localStorage.setItem("accessToken", serverResponse.data.accessToken);
      localStorage.setItem("refreshToken", serverResponse.data.refreshToken);
      console.log("walletID", serverResponse.data?.user?.walletId);
      // localStorage.setItem("wallet", serverResponse.data?.user?.walletId);
      // this.wallet = serverResponse.data?.user?.walletId;
      console.log("fetchAuthRefresh", serverResponse.data);
      await this.setProfileData();
      this.setAuth(true);
    } catch (error) {
      // window.location.reload();
      console.log("error", error);
    } finally {
      console.log("finally");
      this.setLoading(false);
    }
  };
}
