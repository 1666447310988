import React, {useEffect, useState} from "react";
import styles from "./ProgressBarNft.module.scss";
import {observer} from "mobx-react-lite";
import abiContractNft from "../../openMysteryBoxPage/sectionMysteryBoxes/abiNftContract.json";
import cl from "classnames";
import Web3 from "web3";
import {AbiItem} from 'web3-utils';
import {ADDRESS_CONTRACT_NFT, AMOUNT_NFT_BOXES, RPC_BSC} from "../../config/config";


export const ProgressBarNft: React.FC<{ className: string }> = observer(({className}) => {

  const [countNft, setCounHft] = useState<number>(1);

  const getCountNft = async () => {
    try {
      const web3 = new Web3(RPC_BSC);
      const contract = new web3.eth.Contract(
        abiContractNft as AbiItem[],
        ADDRESS_CONTRACT_NFT
      );
      const result = await contract.methods.getCurrentToken().call();

      setCounHft((Number(result) + 9058));
    } catch (error) {
      console.error('error get nft', error);
    }
  };
  useEffect(() => {
    getCountNft();
    setInterval(() => {
      getCountNft();
    }, 20000);
  }, [])
  return (
    <>
      <div className={styles.loading}>loading...</div>
      <div className={cl(styles.bar, className)}>
      </div>
      {/*width change after reboot page*/}
      {window.innerWidth >= 1312 &&
        <div className={styles.fillingProgressBar} style={{width: `${1000 * (countNft / AMOUNT_NFT_BOXES)}px`}}></div>}
      {(window.innerWidth < 1312 && window.innerWidth > 1120) &&
        <div className={styles.fillingProgressBar} style={{width: `${1160 * (countNft / AMOUNT_NFT_BOXES)}px`}}></div>}
      {window.innerWidth <= 1120 &&
        <div className={styles.fillingProgressBar} style={{width: `${289 * (countNft / AMOUNT_NFT_BOXES)}px`}}></div>}
      <div className={styles.soldNft}>{((countNft / AMOUNT_NFT_BOXES) * 100).toFixed(1)}% sold</div>
    </>
  );
});

