import React from "react";
import styles from "./ButtonFormHexagonPurpleBlue.module.scss"
import cl from "classnames";

export const ButtonFormHexagonPurpleBlue: React.FC<any> = ({children, className, ...props}) => {

  return (<div className={cl(styles.button, className)} {...props}>
      {children}
    </div>
  )
}