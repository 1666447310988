import React from "react";
import styles from "./SectionsStaking.module.scss";
import "./SectionsStaking.scss";
import img1 from "../../assets/image/SwordSL.png"
import img2 from "../../assets/image/SwordSR.png"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


export const SectionsStaking: React.FC = () => {

  const block1 =
    <div className={styles.wrraper__block}>
      <div className={styles.block}>
        One of the main features of the Pixie platform is the ability to stake PIX. From each game, 6% automatically
        goes to the staking pool, which is used to pay out PIX staking interest to everyone who’s staking now.
      </div>
    </div>
  const block2 =
    <div className={styles.wrraper__block}>
      <div className={styles.block}>
        Different participants, depending on their grade, have different shares in the staking pool. The higher is your
        grade, the larger interest you share and, accordingly, there is more income.
      </div>
    </div>
  const block3 =
    <div className={styles.wrraper__block}>
      <div className={styles.block}>
        The more the staking pool is filled, the more profitability you have. On the platform, you can even earn 3000%
        per annum, just keep an eye on the staking pool and stake PIX tokens.
      </div>
    </div>
  const responsive = {
    0: {items: 1},
    700: {items: 2}
  };
  return (
    <div className={styles.content}>
      <div className={styles.title}>
        <div className={styles.title__letf}>
          <img src={img1} alt=""/>
        </div>
        <div className={styles.title__center}>
          staking
        </div>
        <div className={styles.title__rigth}>
          <img src={img2} alt=""/>
        </div>
      </div>
      <div className={styles.blocks}>
        {block1}
        {block2}
        {block3}
      </div>
      <div className={styles.slider}>
        <AliceCarousel disableButtonsControls responsive={responsive} controlsStrategy="alternate" mouseTracking
                       items={[
                         block1,
                         block2,
                         block3]}/>

      </div>

    </div>
  );
};

