import React, {useContext, useEffect, useState} from "react";
import styles from "./sectionMysteryBoxes.module.scss";
import warningMark from "../../assets/image/imgForOpenMysteryBoxPage/warning.png";
import wallet from "../../assets/image/imgForOpenMysteryBoxPage/wallet.png";
import mysteryPack from "../../assets/image/imgForOpenMysteryBoxPage/MysteryBox2.png";
import {ButtonFormHexagonPurpleBlue} from "../../components/ButtonFormHexagonPurpleBlue/ButtonFormHexagonPurpleBlue";
import cl from "classnames";
import {Context} from "../../index";
import {modal} from "../../components/modal";
import {ModalMysteryBox} from "../../components/modal/components/ModalMysteryBox/ModalMysteryBox";
import {getEthereumAccountInfo, IEthereumAccountInfo} from "../../utills/metamask/metamask";
import {ethers} from "ethers";
import {ADDRESS_CONTRACT_NFT, NET} from "../../config/config";
import {toast} from "react-toastify";
import abiContractNft from "./abiNftContract.json"
import {
  DescriptionAndOpenMysteryBox
} from "../../components/modal/components/descriptionAndOpenMysteryBox/descriptionAndOpenMysteryBox";
import {observer} from "mobx-react-lite";
import {CHAIN_SETTINGS} from "../../utills/metamask/networkParams";

export const SectionMysteryBoxes: React.FC = observer(() => {

  const [active, setActive] = useState<number>();
  const {storeUser} = useContext(Context);
  const [isLoad, setIsLoad] = useState(false);
  const [arrayNft, setArrayNft] = useState<any[]>([]);
  const getNft = async () => {
    if (storeUser.isAuth) {
      try {
        const ethereumAccount: IEthereumAccountInfo = await getEthereumAccountInfo(NET === 'mainnet' ? CHAIN_SETTINGS.BNB_MAINNET.id : 97) as IEthereumAccountInfo;
        const nftContract = await new ethers.Contract(ADDRESS_CONTRACT_NFT, abiContractNft, ethereumAccount.signer);
        const tx: any[] = await nftContract.getMyNFTs();
        console.log('nfts', tx);
        const items = await Promise.all(
          tx.map(async (box: any) => {
            const item = await fetch(box.nft_type_1).then((response) => response.json());
            if (item.characteristic.type === 'House') {
              return {
                type: item.characteristic.type,
                image: item.image,
                tokenId: box.tokenId,
                minted: box.minted,
                rarity: item.characteristic.rarity,
                referralBonus: item.characteristic.referralBonus,
                roominess: item.characteristic.roominess,
                sustainability: item.characteristic.sustainability,
                profitability: item.characteristic.profitability,
                discountForUpgrades: item.characteristic.discountForUpgrades,
                productivity: item.characteristic.productivity,
                floors: item.characteristic.floors,
              };
            }
            if (item.characteristic.type === 'License') {
              return {
                type: item.characteristic.type,
                image: item.image,
                tokenId: box.tokenId,
                minted: box.minted,
                exclusivityLevel: item.characteristic.exclusivityLevel,
              };
            }
            if (item.characteristic.type === 'Land') {
              return {
                type: item.characteristic.type,
                image: item.image,
                tokenId: box.tokenId,
                minted: box.minted,
                rarity: item.characteristic.rarity,
                exclusivity: item.characteristic.exclusivity,
                profitability: item.characteristic.profitability,
                miningFunPIXtokens: item.characteristic.miningFunPIXtokens,
              };
            }
          })
        );
        setArrayNft(items);
      } catch (error) {
        console.log('error get nft', error);
        toast.error("error get nft");
      }
    }
  };
  const getNftLoadPage = async () => {
    try {
      const ethereumAccount: IEthereumAccountInfo = await getEthereumAccountInfo(NET === 'mainnet' ? CHAIN_SETTINGS.BNB_MAINNET.id : 97) as IEthereumAccountInfo;
      const nftContract = await new ethers.Contract(ADDRESS_CONTRACT_NFT, abiContractNft, ethereumAccount.signer);
      const tx: any[] = await nftContract.getMyNFTs();
      const items = await Promise.all(
        tx.map(async (box: any) => {
          const item = await fetch(box.nft_type_1).then((response) => response.json());
          if (item.characteristic.type === 'House') {
            return {
              type: item.characteristic.type,
              image: item.image,
              tokenId: box.tokenId,
              minted: box.minted,
              rarity: item.characteristic.rarity,
              referralBonus: item.characteristic.referralBonus,
              roominess: item.characteristic.roominess,
              sustainability: item.characteristic.sustainability,
              profitability: item.characteristic.profitability,
              discountForUpgrades: item.characteristic.discountForUpgrades,
              productivity: item.characteristic.productivity,
              floors: item.characteristic.floors,
            };
          }
          if (item.characteristic.type === 'License') {
            return {
              type: item.characteristic.type,
              image: item.image,
              tokenId: box.tokenId,
              minted: box.minted,
              exclusivityLevel: item.characteristic.exclusivityLevel,
            };
          }
          if (item.characteristic.type === 'Land') {
            return {
              type: item.characteristic.type,
              image: item.image,
              tokenId: box.tokenId,
              minted: box.minted,
              rarity: item.characteristic.rarity,
              exclusivity: item.characteristic.exclusivity,
              profitability: item.characteristic.profitability,
              miningFunPIXtokens: item.characteristic.miningFunPIXtokens,
            };
          }
        })
      );
      setArrayNft(items);
      console.log('nft box', items);
    } catch (error) {
      console.log('error get nft', error);
      toast.error("error get nft");
    }

  };


  useEffect(() => {
    if (storeUser.isAuth) {
      getNftLoadPage();
    }
    setInterval(() => {
      if (storeUser.isAuth) {
        getNft();
      }
    }, 10000);

  }, [storeUser.isOenNft])

  useEffect(() => {
    if (storeUser.isAuth) {
      getNftLoadPage();
    }
  }, [storeUser.isAuth])

  let myIndex = 0;

  return (
    <div className={styles.content}>
      <div className={styles.addingInform}>
        <div className={styles.address}><img src={wallet} alt={''}/>
          {storeUser.isAuth ? <>{storeUser.wallet}</> : <>please connect your wallet</>}
        </div>
        <div className={styles.warning} onClick={() => {
        }}><img src={warningMark} alt={''}/>please make sure you’re using the correct
          wallet
        </div>
      </div>
      {storeUser.isAuth && <div className={styles.areaForBoxes}>
        {arrayNft.map((box, index) => {
          myIndex = myIndex + 1;
          if (!box.minted) {
            return (
              <div className={cl(styles.box, {[styles.active]: active === index})} onClick={() => {
                setActive(index);
                modal.open(<DescriptionAndOpenMysteryBox
                  idPack={parseInt(arrayNft[index].tokenId._hex, 16)}/>, {className: 'none'})
              }}>
                <div className={styles.titleBox}>mystery box #{parseInt(box.tokenId._hex, 16)}</div>
                <div className={styles.boxInCell}><img src={mysteryPack} alt={''}/></div>
              </div>
            )
          } else {

            return (
              <>
                <div className={cl(styles.box, {[styles.active]: active === index})} onClick={() => {
                  active === index ? setActive(-1) : setActive(index);
                }}>
                  <div className={styles.titleBox}>#{parseInt(box.tokenId._hex, 16)} {box.type}</div>
                  <div className={styles.boxInCell}><img src={box.image} alt={''}/></div>
                  {(active === index && (myIndex % 3 !== 0)) && <div className={styles.characteristics}>
                    <div className={styles.characteristicsTittle}>#{parseInt(box.tokenId._hex, 16)} {box.type}</div>
                    <div className={styles.nameCharacteristics}>
                      {(box.rarity === 0 || box.rarity) && <div>rarity</div>}
                      {(box.referralBonus === 0 || box.referralBonus) && <div>referral bonus</div>}
                      {(box.roominess === 0 || box.roominess) && <div>roominess</div>}
                      {(box.profitability === 0 || box.profitability) && <div>PROFITABILITY</div>}
                      {(box.discountForUpgrades === 0 || box.discountForUpgrades) && <div>DISCOUNT FOR UPGRADES</div>}
                      {(box.productivity === 0 || box.productivity) && <div>PRODUCTIVITY</div>}
                      {(box.floors === 0 || box.floors) && <div>FLOORS</div>}
                      {(box.exclusivity === 0 || box.exclusivity) && <div>exclusivity</div>}
                      {(box.miningFunPIXtokens === 0 || box.miningFunPIXtokens) && <div>mining FunPIX tokens</div>}
                      {(box.exclusivityLevel === 0 || box.exclusivityLevel) && <div>exclusivityLevel</div>}
                      {(box.sustainability === 0 || box.sustainability) && <div>sustainability</div>}
                    </div>
                    <div className={styles.valueCharacteristics}>
                      {box.rarity && <div>{box.rarity}</div>}
                      {box.referralBonus && <div>{box.referralBonus}</div>}
                      {box.roominess && <div>{box.roominess}</div>}
                      {box.profitability && <div>{box.profitability}</div>}
                      {box.discountForUpgrades && <div>{box.discountForUpgrades}</div>}
                      {box.productivity && <div>{box.productivity}</div>}
                      {box.floors && <div>{box.floors}</div>}
                      {box.exclusivity && <div>{box.exclusivity}</div>}
                      {box.miningFunPIXtokens && <div>{box.miningFunPIXtokens}</div>}
                      {box.exclusivityLevel && <div>{box.exclusivityLevel}</div>}
                      {box.sustainability && <div className={styles.sustainability}>{box.sustainability}</div>}
                    </div>
                  </div>}
                  {(active === index && (myIndex % 3 == 0)) && <div className={styles.characteristicsLeft}>
                    <div className={styles.characteristicsTittle}>#{parseInt(box.tokenId._hex, 16)} {box.type}</div>
                    <div className={styles.nameCharacteristics}>
                      {(box.rarity === 0 || box.rarity) && <div>rarity</div>}
                      {(box.referralBonus === 0 || box.referralBonus) && <div>referral bonus</div>}
                      {(box.roominess === 0 || box.roominess) && <div>roominess</div>}
                      {(box.profitability === 0 || box.profitability) && <div>PROFITABILITY</div>}
                      {(box.discountForUpgrades === 0 || box.discountForUpgrades) && <div>DISCOUNT FOR UPGRADES</div>}
                      {(box.productivity === 0 || box.productivity) && <div>PRODUCTIVITY</div>}
                      {(box.floors === 0 || box.floors) && <div>FLOORS</div>}
                      {(box.exclusivity === 0 || box.exclusivity) && <div>exclusivity</div>}
                      {(box.miningFunPIXtokens === 0 || box.miningFunPIXtokens) && <div>mining FunPIX tokens</div>}
                      {(box.exclusivityLevel === 0 || box.exclusivityLevel) && <div>exclusivityLevel</div>}
                      {(box.sustainability === 0 || box.sustainability) && <div>sustainability</div>}
                    </div>
                    <div className={styles.valueCharacteristics}>
                      {box.rarity && <div>{box.rarity}</div>}
                      {box.referralBonus && <div>{box.referralBonus}</div>}
                      {box.roominess && <div>{box.roominess}</div>}
                      {box.profitability && <div>{box.profitability}</div>}
                      {box.discountForUpgrades && <div>{box.discountForUpgrades}</div>}
                      {box.productivity && <div>{box.productivity}</div>}
                      {box.floors && <div>{box.floors}</div>}
                      {box.exclusivity && <div>{box.exclusivity}</div>}
                      {box.miningFunPIXtokens && <div>{box.miningFunPIXtokens}</div>}
                      {box.exclusivityLevel && <div>{box.exclusivityLevel}</div>}
                      {box.sustainability && <div className={styles.sustainability}>{box.sustainability}</div>}
                    </div>
                  </div>}
                  {(active === index && (myIndex % 2 !== 0)) && <div className={styles.characteristicsMiddle}>
                    <div className={styles.characteristicsTittle}>#{parseInt(box.tokenId._hex, 16)} {box.type}</div>
                    <div className={styles.nameCharacteristics}>
                      {(box.rarity === 0 || box.rarity) && <div>rarity</div>}
                      {(box.referralBonus === 0 || box.referralBonus) && <div>referral bonus</div>}
                      {(box.roominess === 0 || box.roominess) && <div>roominess</div>}
                      {(box.profitability === 0 || box.profitability) && <div>PROFITABILITY</div>}
                      {(box.discountForUpgrades === 0 || box.discountForUpgrades) && <div>DISCOUNT FOR UPGRADES</div>}
                      {(box.productivity === 0 || box.productivity) && <div>PRODUCTIVITY</div>}
                      {(box.floors === 0 || box.floors) && <div>FLOORS</div>}
                      {(box.exclusivity === 0 || box.exclusivity) && <div>exclusivity</div>}
                      {(box.miningFunPIXtokens === 0 || box.miningFunPIXtokens) && <div>mining FunPIX tokens</div>}
                      {(box.exclusivityLevel === 0 || box.exclusivityLevel) && <div>exclusivityLevel</div>}
                      {(box.sustainability === 0 || box.sustainability) && <div>sustainability</div>}
                    </div>
                    <div className={styles.valueCharacteristics}>
                      {box.rarity && <div>{box.rarity}</div>}
                      {box.referralBonus && <div>{box.referralBonus}</div>}
                      {box.roominess && <div>{box.roominess}</div>}
                      {box.profitability && <div>{box.profitability}</div>}
                      {box.discountForUpgrades && <div>{box.discountForUpgrades}</div>}
                      {box.productivity && <div>{box.productivity}</div>}
                      {box.floors && <div>{box.floors}</div>}
                      {box.exclusivity && <div>{box.exclusivity}</div>}
                      {box.miningFunPIXtokens && <div>{box.miningFunPIXtokens}</div>}
                      {box.exclusivityLevel && <div>{box.exclusivityLevel}</div>}
                      {box.sustainability && <div className={styles.sustainability}>{box.sustainability}</div>}
                    </div>
                  </div>}
                  {(active === index && (myIndex % 2 == 0)) && <div className={styles.characteristicsLeftMiddle}>
                    <div className={styles.characteristicsTittle}>#{parseInt(box.tokenId._hex, 16)} {box.type}</div>
                    <div className={styles.nameCharacteristics}>
                      {(box.rarity === 0 || box.rarity) && <div>rarity</div>}
                      {(box.referralBonus === 0 || box.referralBonus) && <div>referral bonus</div>}
                      {(box.roominess === 0 || box.roominess) && <div>roominess</div>}
                      {(box.profitability === 0 || box.profitability) && <div>PROFITABILITY</div>}
                      {(box.discountForUpgrades === 0 || box.discountForUpgrades) && <div>DISCOUNT FOR UPGRADES</div>}
                      {(box.productivity === 0 || box.productivity) && <div>PRODUCTIVITY</div>}
                      {(box.floors === 0 || box.floors) && <div>FLOORS</div>}
                      {(box.exclusivity === 0 || box.exclusivity) && <div>exclusivity</div>}
                      {(box.miningFunPIXtokens === 0 || box.miningFunPIXtokens) && <div>mining FunPIX tokens</div>}
                      {(box.exclusivityLevel === 0 || box.exclusivityLevel) && <div>exclusivityLevel</div>}
                      {(box.sustainability === 0 || box.sustainability) && <div>sustainability</div>}
                    </div>
                    <div className={styles.valueCharacteristics}>
                      {box.rarity && <div>{box.rarity}</div>}
                      {box.referralBonus && <div>{box.referralBonus}</div>}
                      {box.roominess && <div>{box.roominess}</div>}
                      {box.profitability && <div>{box.profitability}</div>}
                      {box.discountForUpgrades && <div>{box.discountForUpgrades}</div>}
                      {box.productivity && <div>{box.productivity}</div>}
                      {box.floors && <div>{box.floors}</div>}
                      {box.exclusivity && <div>{box.exclusivity}</div>}
                      {box.miningFunPIXtokens && <div>{box.miningFunPIXtokens}</div>}
                      {box.exclusivityLevel && <div>{box.exclusivityLevel}</div>}
                      {box.sustainability && <div className={styles.sustainability}>{box.sustainability}</div>}
                    </div>
                  </div>}
                  {(active === index) && <div className={styles.characteristicsLeftMobile}>
                    <div className={styles.characteristicsTittle}>#{parseInt(box.tokenId._hex, 16)} {box.type}</div>
                    <div className={styles.nameCharacteristics}>
                      {(box.rarity === 0 || box.rarity) && <div>rarity</div>}
                      {(box.referralBonus === 0 || box.referralBonus) && <div>referral bonus</div>}
                      {(box.roominess === 0 || box.roominess) && <div>roominess</div>}
                      {(box.profitability === 0 || box.profitability) && <div>PROFITABILITY</div>}
                      {(box.discountForUpgrades === 0 || box.discountForUpgrades) && <div>DISCOUNT FOR UPGRADES</div>}
                      {(box.productivity === 0 || box.productivity) && <div>PRODUCTIVITY</div>}
                      {(box.floors === 0 || box.floors) && <div>FLOORS</div>}
                      {(box.exclusivity === 0 || box.exclusivity) && <div>exclusivity</div>}
                      {(box.miningFunPIXtokens === 0 || box.miningFunPIXtokens) && <div>mining FunPIX tokens</div>}
                      {(box.exclusivityLevel === 0 || box.exclusivityLevel) && <div>exclusivityLevel</div>}
                      {(box.sustainability === 0 || box.sustainability) && <div>sustainability</div>}
                    </div>
                    <div className={styles.valueCharacteristics}>
                      {box.rarity && <div>{box.rarity}</div>}
                      {box.referralBonus && <div>{box.referralBonus}</div>}
                      {box.roominess && <div>{box.roominess}</div>}
                      {box.profitability && <div>{box.profitability}</div>}
                      {box.discountForUpgrades && <div>{box.discountForUpgrades}</div>}
                      {box.productivity && <div>{box.productivity}</div>}
                      {box.floors && <div>{box.floors}</div>}
                      {box.exclusivity && <div>{box.exclusivity}</div>}
                      {box.miningFunPIXtokens && <div>{box.miningFunPIXtokens}</div>}
                      {box.exclusivityLevel && <div>{box.exclusivityLevel}</div>}
                      {box.sustainability && <div className={styles.sustainability}>{box.sustainability}</div>}
                    </div>
                  </div>}
                </div>
              </>
            )
          }
        })}
      </div>}
      {!storeUser.isPaymentNewBox ?
        <ButtonFormHexagonPurpleBlue className={styles.button} onClick={() => {
          modal.open(<ModalMysteryBox/>);
          storeUser.isPaymentNewBox = true;
        }}>buy nfts</ButtonFormHexagonPurpleBlue> :
        <ButtonFormHexagonPurpleBlue className={styles.button} onClick={() => {
          toast('wait for the previous payment to be completed')
        }}>buy nfts</ButtonFormHexagonPurpleBlue>
      }
    </div>
  )
});

