import {AxiosResponse} from "axios";
import agent from "./agent/axios";

export const fetchCheckPayment = (
  hash?: string
): Promise<AxiosResponse<any>> => {
  return agent.post(`/profile/check_payment`, {hash: hash});
};
export const fetchCheckPaymentBnb = (hash: string): Promise<AxiosResponse<any>> => {
  return agent.post(`/profile/check_payment_bnb`, {hash: hash});
};

export const fetchCheckPaymentBusd = (
  hash: string
): Promise<AxiosResponse<any>> => {
  return agent.post(`/profile/check_payment_busd`, {hash: hash});
};

export const fetchWithdraw = (
  amount: string,
  recipient: string
): Promise<AxiosResponse<any>> => {
  return agent.post(`/profile/withdraw`, {
    amount: amount,
    recipient: recipient,
  });
};


export const fetchCheckNftPayment = (count: number): Promise<AxiosResponse<any>> => {
  return agent.post(`/profile/check_nft_payment`, {count});
};

export const fetchGetPricePack = (): Promise<AxiosResponse<any>> => {
  return agent.get('/nft/get_price');
}
