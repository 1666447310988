import axios from "axios";
import {SERVER_DOMAIN} from "../../config/config";


const agent = axios.create({
  // withCredentials: true,
  baseURL: SERVER_DOMAIN,
});

agent.defaults.headers.post["Content-Type"] = "multipart/form-data";

agent.interceptors.request.use((config: any) => {
  const result = {...config};
  if (result.headers) {
    result.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
  }
  return result;
});

agent.interceptors.response.use(
  (config: any) => {
    return config;
  },
  async (error: any) => {
    const originRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originRequest._isRetry = true;
      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const serverResponse = await agent.post(`/auth/refresh`, {
          refreshToken: refreshToken,
        });

        localStorage.setItem("accessToken", serverResponse.data.accessToken);
        localStorage.setItem("refreshToken", serverResponse.data.refreshToken);
        localStorage.setItem("wallet", serverResponse.data.user.walletId);
        // localStorage.setItem("typeAuthorization", serverResponse.data.user.walletId);

        return agent.request(originRequest);
      } catch (repeatedError) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("wallet");
      }
    }
    // When error code !== 401
    throw error;
  }
);

export default agent;
