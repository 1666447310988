import React from "react";

export const SvgLogoTwitter: React.FC = () => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 1.77361C17.332 2.07642 16.6184 2.27469 15.873 2.37203C16.6395 1.90339 17.223 1.16799 17.4973 0.281182C16.7836 0.717376 15.9961 1.02379 15.1559 1.19683C14.4809 0.457823 13.5141 0 12.4629 0C10.4203 0 8.775 1.70151 8.775 3.78515C8.775 4.08435 8.79961 4.37275 8.85938 4.64672C5.79023 4.49531 3.07617 2.98486 1.25156 0.688536C0.931641 1.25451 0.745313 1.90339 0.745313 2.60274C0.745313 3.91492 1.40273 5.0757 2.38359 5.74621C1.78945 5.7354 1.20938 5.55876 0.717187 5.28118C0.717187 5.292 0.717187 5.30642 0.717187 5.32444C0.717187 7.16294 1.99687 8.69142 3.67383 9.0411C3.375 9.12401 3.04453 9.16727 2.70703 9.16727C2.47148 9.16727 2.23242 9.15285 2.00742 9.10238C2.48555 10.602 3.84258 11.7015 5.45625 11.7376C4.20117 12.7433 2.60508 13.3526 0.882422 13.3526C0.580078 13.3526 0.288281 13.3381 0 13.3021C1.63477 14.3836 3.57187 15 5.66016 15C12.4523 15 16.1648 9.23215 16.1648 4.23216C16.1648 4.06633 16.1578 3.90411 16.1508 3.74189C16.882 3.20836 17.4973 2.54146 18 1.77361Z"
        fill="white"/>
    </svg>
  );
};
