import cl from "classnames";
import React from "react";
import ImageCoins from '../../assets/image/Coins.png';
import ImageFlask from '../../assets/image/Flask.png';
import HomeAndTree from '../../assets/image/HomeAndTree.png';
import ImageGirls from '../../assets/image/ImageGirls1.png';
import ImageSword from '../../assets/image/Sword.png';
import { RoadmapStage } from "../../components/RoadmapStage/RoadmapStage";
import { RoadmapStep } from "../../components/RoadmapStep/RoadmapStep";
import styles from "./SectionsRoadmap.module.scss";
import { SvgRoadmapLine } from "./SvgRoadmapLine";


export const SectionsRoadmap: React.FC = () => {

  return (
    <div className={styles.content}>
      <div className={styles.title}>
        roadmap
        <svg width="306" height="93" viewBox="0 0 306 93" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="18.7347" y1="3.5" x2="1.13542" y2="3.5" stroke="#E74FF1" strokeWidth="7" />
          <line x1="3.5" y1="8.17705e-08" x2="3.49999" y2="92" stroke="#E74FF1" strokeWidth="7" />
          <line x1="0.567719" y1="89.5" x2="18.7347" y2="89.5" stroke="#E74FF1" strokeWidth="7" />
          <line y1="-3.5" x2="17.5993" y2="-3.5" transform="matrix(1 8.23049e-08 9.28589e-08 -1 287.265 0)"
            stroke="#E74FF1" strokeWidth="7" />
          <line y1="-3.5" x2="92" y2="-3.5" transform="matrix(4.64295e-08 1 1 -4.11525e-08 306 0)" stroke="#E74FF1"
            strokeWidth="7" />
          <line y1="-3.5" x2="18.167" y2="-3.5" transform="matrix(-1 0 0 1 305.432 93)" stroke="#E74FF1"
            strokeWidth="7" />
        </svg>
      </div>
      <div className={styles.flare}>
        <svg width="1592" height="1308" viewBox="0 0 1592 1308" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_f_111_10)">
            <circle cx="611.5" cy="611.5" r="161.5" fill="#E751BD" fill-opacity="0.5" />
          </g>
          <g filter="url(#filter1_f_111_10)">
            <circle cx="980.5" cy="696.5" r="161.5" fill="#B5E627" fill-opacity="0.5" />
          </g>
          <defs>
            <filter id="filter0_f_111_10" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_111_10" />
            </filter>
            <filter id="filter1_f_111_10" x="369" y="85" width="1223" height="1223" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_111_10" />
            </filter>
          </defs>
        </svg>

      </div>
      <div className={styles.oneArrow}>
        <svg width="31" height="155" viewBox="0 0 31 155" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.5 31L28.9234 7.75H2.07661L15.5 31Z" fill="white" />
          <line x1="16.5" y1="65" x2="16.5" y2="155" stroke="white" strokeWidth="5" strokeDasharray="13 13" />
        </svg>
      </div>


      <div className={cl(styles.stage, styles.stage0)}>
        <div className={styles.titleLeft}>
          <RoadmapStage title={"ongoing"} status={'in process'} />
        </div>
        <div className={styles.stage0__content}>
          <div className={styles.stage0__content_row}>
            <RoadmapStep>
              Upgrading Pixie Families (adding marketplaces, special skins, boosts, passive income multipliers)
            </RoadmapStep>
            <RoadmapStep>
              Pixie Families battles
            </RoadmapStep>
            <RoadmapStep>
              PvP challenges
            </RoadmapStep>
            <RoadmapStep>
              Pixie Codes
            </RoadmapStep>
            <RoadmapStep>
              Daily Combos
            </RoadmapStep>
            <RoadmapStep>
              Daily Lucky wheel spin
            </RoadmapStep>
          </div>
          <div className={styles.stage0__content_row}>
            <RoadmapStep>
              Easter eggs
            </RoadmapStep>
            <RoadmapStep>
              New licences
            </RoadmapStep>
            <RoadmapStep>
              New skins for Pixies and Coins
            </RoadmapStep>
            <RoadmapStep>
              New upgrade cards for your studio
            </RoadmapStep>
          </div>
        </div>
        <div className={styles.stage0__img}>
          <img src={HomeAndTree} alt="" />
        </div>
      </div>
      <SvgRoadmapLine />
      <div className={cl(styles.stage, styles.stage1)}>
        <div className={styles.titleRigth}>
          <RoadmapStage title={"stage 1"} status={'q4 ‘ 2024'} />
        </div>
        <div className={styles.stage1__img}>
          <img src={ImageGirls} alt="" />
        </div>
        <div className={styles.stage1__flare}>
          <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_39_1124)">
              <circle cx="611.5" cy="611.5" r="161.5" fill="#E751BD" fill-opacity="0.5" />
            </g>
            <defs>
              <filter id="filter0_f_39_1124" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1124" />
              </filter>
            </defs>
          </svg>

        </div>
        <div className={styles.stage1__content}>
          <RoadmapStep>
            Pixie opinions monetisation platform beta in Bot
          </RoadmapStep>
          <RoadmapStep>
            Integrations with new partners
          </RoadmapStep>
          <RoadmapStep>
            Closing the token farming
          </RoadmapStep>
          <RoadmapStep>
            Cryptocurrency price prediction games with tokens or USDT
          </RoadmapStep>
          <RoadmapStep>
            Custom events' prediction games with tokens or USDT
          </RoadmapStep>
          <RoadmapStep>
            Token airdrop
          </RoadmapStep>
          <RoadmapStep>
            Token listing
          </RoadmapStep>

        </div>
      </div>
      <SvgRoadmapLine />
      <div className={cl(styles.stage, styles.stage2)}>
        <div className={styles.titleLeft}>
          <RoadmapStage title={"stage 2"} status={'q1 ‘ 2025'} />
        </div>
        <div className={styles.stage2__flare1}>
          <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_39_1128)">
              <circle cx="611.5" cy="611.5" r="161.5" fill="#B5E627" fill-opacity="0.5" />
            </g>
            <defs>
              <filter id="filter0_f_39_1128" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1128" />
              </filter>
            </defs>
          </svg>

        </div>
        <div className={styles.stage2__flare2}>
          <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_39_1125)">
              <circle cx="611.5" cy="611.5" r="161.5" fill="#E751BD" fill-opacity="0.5" />
            </g>
            <defs>
              <filter id="filter0_f_39_1125" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1125" />
              </filter>
            </defs>
          </svg>
        </div>
        <div className={styles.stage2__content}>
          <RoadmapStep>
            Update of the platform functionalities
          </RoadmapStep>
          <RoadmapStep>
            FunPIX tokens release (compensational token)
          </RoadmapStep>
          <RoadmapStep>
            PIX and USDT allocation across all NFT holders and Pixie Family contests
          </RoadmapStep>
          <RoadmapStep>
            Lottery
          </RoadmapStep>
          <RoadmapStep>
            NFT giveaways and sale
          </RoadmapStep>
          <RoadmapStep>
            New Pixie Families contests
          </RoadmapStep>
        </div>
      </div>
      <SvgRoadmapLine />
      <div className={cl(styles.stage, styles.stage3)}>
        <div className={styles.titleRigth}>
          <RoadmapStage title={"stage 3"} status={'q2 ‘ 2025'} />
        </div>

        <div className={styles.stage3__flare1}>
          <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_39_1129)">
              <circle cx="611.5" cy="611.5" r="161.5" fill="#B5E627" fill-opacity="0.5" />
            </g>
            <defs>
              <filter id="filter0_f_39_1129" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1129" />
              </filter>
            </defs>
          </svg>
        </div>
        <div className={styles.stage3__flare2}>
          <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_39_1126)">
              <circle cx="611.5" cy="611.5" r="161.5" fill="#E751BD" fill-opacity="0.5" />
            </g>
            <defs>
              <filter id="filter0_f_39_1126" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1126" />
              </filter>
            </defs>
          </svg>
        </div>
        <div className={styles.stage3__img1}>
          <img src={ImageSword} alt="" />
        </div>
        <div className={styles.stage3__img2}>
          <img src={ImageFlask} alt="" />
        </div>
        <div className={styles.stage3__content}>
          <RoadmapStep>
            Metaverse beta release
          </RoadmapStep>
          <RoadmapStep>
            PIX burning mechanism
          </RoadmapStep>
          <RoadmapStep>
            Platform full release
          </RoadmapStep>
          <RoadmapStep>
            Marketplace
          </RoadmapStep>
          <RoadmapStep>
            Pixie customization
          </RoadmapStep>
          <RoadmapStep>
            NFTs combination
          </RoadmapStep>
        </div>
      </div>
      <SvgRoadmapLine />
      <div className={cl(styles.stage, styles.stage4)}>
        <div className={styles.titleLeft}>
          <RoadmapStage title={"stage 4"} status={'q3 ‘ 2025'} />
        </div>
        <div className={styles.stage4__img}>
          <img src={ImageCoins} alt="" />
        </div>
        <div className={styles.stage4__flare1}>
          <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_39_1130)">
              <circle cx="611.5" cy="611.5" r="161.5" fill="#B5E627" fill-opacity="0.5" />
            </g>
            <defs>
              <filter id="filter0_f_39_1130" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1130" />
              </filter>
            </defs>
          </svg>
        </div>
        <div className={styles.stage4__flare2}>
          <svg width="1223" height="1223" viewBox="0 0 1223 1223" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_39_1127)">
              <circle cx="611.5" cy="611.5" r="161.5" fill="#E751BD" fill-opacity="0.5" />
            </g>
            <defs>
              <filter id="filter0_f_39_1127" x="0" y="0" width="1223" height="1223" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="225" result="effect1_foregroundBlur_39_1127" />
              </filter>
            </defs>
          </svg>
        </div>
        <div className={styles.stage4__content}>
          <RoadmapStep>
            To be updated
          </RoadmapStep>
        </div>
      </div>
    </div>
  );
};

