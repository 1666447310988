import React, {useContext} from "react";

import styles from "./PixieNft.module.scss";
import {modal} from "../../components/modal";
import {ModalMysteryBox} from "../../components/modal/components/ModalMysteryBox/ModalMysteryBox";
import {toast} from "react-toastify";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import BoxInformationArea from "../../components/BoxInformationArea/BoxInformationArea";
import pixie1 from "../../assets/image/pixie1.png";
import pixie2 from "../../assets/image/pixie2.png";
import {ButtonFormHexagonPurpleBlue} from "../../components/ButtonFormHexagonPurpleBlue/ButtonFormHexagonPurpleBlue";
import sealRed from "../../assets/image/sealRed.png";
import playVideoButton from "../../assets/image/playVideoButton.png";

export const PixieNft: React.FC = observer(() => {
  const {storeUser} = useContext(Context);

  return (
    <div className={styles.content}>
      <div className={styles.Maintitle}>
        Pixie NFT <br/> Mystery Box Sale
      </div>
      <div className={styles.subTitleBlock}>
        The only one NFT that brings passive income
        <div className={styles.subTitleGrad}>
          every 5 minutes in USDT
        </div>
        no matter what your NFT is.
        Every NFT out of 15010 will make you closer to your dream by giving you a stable share of each game played on
        the platform.
        What do you think, Elon?
        <br/>
        Monetize your opinion and let it earn for you.
      </div>

      <div className={styles.buyBox}>
      <div className={styles.buttonVideo} onClick={()=>{
            window.open("https://youtu.be/ljZt1nTlKTg", "_blank")
          }}><img src={playVideoButton} alt={''}/>
            how to mint NFT
          </div>
          {!storeUser.isPaymentNewBox ?
            <ButtonFormHexagonPurpleBlue className={styles.button} onClick={() => {
              modal.open(<ModalMysteryBox/>);
              storeUser.isPaymentNewBox = true;
            }}>buy a box</ButtonFormHexagonPurpleBlue> :
            <ButtonFormHexagonPurpleBlue className={styles.button} onClick={() => {
              toast('wait for the previous payment to be completed')
            }}>buy a box</ButtonFormHexagonPurpleBlue>
          }
      </div>
      <BoxInformationArea className={styles.image}/>
      <img src={pixie1} alt={''} className={styles.pixie1}/>
      <img src={pixie2} alt={''} className={styles.pixie2}/>
      <img src={sealRed} alt={''} className={styles.sealRed}/>
    </div>
  );
});

