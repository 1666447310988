import React from "react";
import styles from "./SectionsRoadmap.module.scss";

export const SvgRoadmapLine: React.FC = () => {
  return (
    <svg className={styles.SvgRoadmapLine} width="5" height="157" viewBox="0 0 5 157" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <line x1="2.5" y1="-1.09278e-07" x2="2.50001" y2="157" stroke="white" strokeWidth="5" strokeDasharray="13 13"/>
    </svg>

  );
};
