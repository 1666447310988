import React from 'react';

interface ITestModalProps {
  time: number
}

export const TestModal = (
  {
    time
  }: ITestModalProps
) => {
  return (
    <div>
      Тестовый компонент
      <br/>
      Окно закроется через {time} милесекунд
    </div>
  );
};
