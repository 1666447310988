import React, {useContext} from "react";

import styles from "./NftHousesAre.module.scss";
import Museum from '../../assets/imageBox/Museum.png'
import StarsBtn from '../../assets/imageBox/StarsBtn.png'
import {modal} from "../../components/modal";
import {ModalMysteryBox} from "../../components/modal/components/ModalMysteryBox/ModalMysteryBox";
import {toast} from "react-toastify";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {ButtonFormHexagonPurpleBlue} from "../../components/ButtonFormHexagonPurpleBlue/ButtonFormHexagonPurpleBlue";

export const NftHousesAre: React.FC = observer(() => {

  const {storeUser} = useContext(Context);
  return (
    <div className={styles.content}>
      <div className={styles.subTitle}>
        Houses add value to your Land that helps unlock all the earning possibilities of your NFT
      </div>
      <div className={styles.center}>
        <div className={styles.left}>
          <div className={styles.item}>
            7500 NFT houses
          </div>
          <div className={styles.item}>
            Can have ads on them as additional passive income
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.item}>
            Customizable to look unique
          </div>
          <div className={styles.item}>
            Can be lent to other players
          </div>
        </div>
      </div>
      <img className={styles.museum} src={Museum} alt=""/>
      <div className={styles.btn}>
        {!storeUser.isPaymentNewBox ?
          <ButtonFormHexagonPurpleBlue className={styles.button} onClick={() => {
            modal.open(<ModalMysteryBox/>);
            storeUser.isPaymentNewBox = true;
          }}>buy a box</ButtonFormHexagonPurpleBlue> :
          <ButtonFormHexagonPurpleBlue className={styles.button} onClick={() => {
            toast('wait for the previous payment to be completed')
          }}>buy a box</ButtonFormHexagonPurpleBlue>
        }
      </div>
      <img className={styles.starsBtn} src={StarsBtn} alt=""/>
    </div>
  );
});

