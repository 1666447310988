import React, {useContext} from "react";
import styles from "./SectionSpecialPrizes.module.scss";
import coin from "../../assets/imageBox/imgForSectionSpecialPrizes/coin.png";
import gift from "../../assets/imageBox/imgForSectionSpecialPrizes/gift.png";
import cashBack from "../../assets/imageBox/imgForSectionSpecialPrizes/cashBack.png";
import bigGift from "../../assets/imageBox/imgForSectionSpecialPrizes/bigGift.png";
import claperBoard from "../../assets/imageBox/imgForSectionSpecialPrizes/claperboard.png";
import shadow from "../../assets/imageBox/imgForSectionSpecialPrizes/shadowForSectionSpecialPrizes.png";


export const SectionSpecialPrizes: React.FC = () => {

  return (
    <div className={styles.content}>
      <div className={styles.title}>
        some boxes have special prizes in them
      </div>
      <div className={styles.sections}>
        <div className={styles.section1}>
          <img src={coin} alt={''}/>
          <div className={styles.description}>prizes from $10 to $1000</div>
        </div>
        <div className={styles.section2}>
          <img src={cashBack} alt={''}/>
          <div className={styles.description}>cashback from 1o% to 99%</div>
        </div>
        <div className={styles.section3}>
          <img src={gift} alt={''}/>
          <div className={styles.description}>Top 100 NFTs are not only digital, but they contain real cards that will be sent to their owners via mail</div>
        </div>
      </div>
      <img src={bigGift} alt={''} className={styles.bigGift}/>
      <img src={claperBoard} alt={''} className={styles.claperBoard}/>
      <img src={shadow} alt={''} className={styles.shadow}/>
    </div>
  );
};

